import { ActionTypes } from '../actionTypes';

const defaultState = {
    favColor: "Blue",
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.DEFAULT_STATE:
            return {
                ...state,
                yaseen: action.yaseen,
            };
        default:
            return state;
    }
}