export const resultsData = {
    strengthWeakness: {
        title: "Your Strength",
        userChosen: "Highly Empathetic / Compassionate",
    },
    domains: [
        {
            id: 1,
            title: 'Mental Health',
            icon: '/assets/icons/domains/client1/mental-health.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 100,  importance: 100,    showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                    { confidence: 25,   importance: 75,     showStem: true, stemColor: "#996633", titleBgColor: "#996633" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "HIGH", label: "<b>Lorem 3</b> ipsum doler set almighty...", link: "https://auth0.com/", },
                    { type: "HIGH", label: "<b>Lorem 4</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "HIGH", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "HIGH", label: "<b>Lorem 6</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 2,
            title: 'Finances',
            icon: '/assets/icons/domains/client1/finances.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 10,   importance: 63,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "MEDIUM", label: "<b>Lorem 3</b> ipsum doler set almighty...", link: "https://auth0.com/", },
                    { type: "MEDIUM", label: "<b>Lorem 4</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 3,
            title: 'Academics',
            icon: '/assets/icons/domains/client1/academics.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 15,   importance: 75,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "MEDIUM", label: "<b>Lorem 4</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "MEDIUM", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 4,
            title: 'Physical Health',
            icon: '/assets/icons/domains/client1/physical-health.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 83,   importance: 76,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "HIGH", label: "<b>Lorem 3</b> ipsum doler set almighty...", link: "https://auth0.com/", },
                ]
            }
        },
        {
            id: 5,
            title: 'Family',
            icon: '/assets/icons/domains/client1/family.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 48,   importance: 24,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "LOW", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "LOW", label: "<b>Lorem 6</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 6,
            title: 'Commuting',
            icon: '/assets/icons/domains/client1/commuting.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 15,   importance: 15,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    { confidence: 75,   importance: 25,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "MEDIUM", label: "<b>Lorem 4</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "MEDIUM", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 7,
            title: 'Concentration',
            icon: '/assets/icons/domains/client1/concentration.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 0,    importance: 0,    showStem: false, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    { confidence: 75,   importance: 25,   showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                ]
            },
            popUp: {
                show: false,
                messages: [
                    { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                    { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                    { type: "HIGH", label: "<b>Lorem 3</b> ipsum doler set almighty...", link: "https://auth0.com/", },
                    { type: "HIGH", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                ]
            }
        },
        {
            id: 8,
            title: 'Belonging',
            icon: '/assets/icons/domains/client1/belonging.svg',
            percents: {
                currPercOptionIdx: 0,
                percOptions: [
                    { confidence: 13,   importance: 57,     showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                ]
            },
            popUp: {
                show: false,
                messages: []
            }
        },
    ],
};
