import { ActionTypes } from '../actionTypes';

const defaultState = {
    loadingFlag: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.LOADING_SUCCESS:
            return {
                ...state,
                loadingFlag: action.loadingFlag
            };
        default:
            return state;
    }
}