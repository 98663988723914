import React from 'react';
import styled from 'styled-components';

const Percentages = ({confPercent, impPercent}) => {
    return (
        <PercentagesStyled className='Percentages'>
            <h2 className='confidence'>Confidence {confPercent}%</h2>
            <h2 className='importance'>Importance {impPercent}%</h2>
        </PercentagesStyled>
    );
}

export default Percentages;

const PercentagesStyled = styled.div`
    h2 {
        margin: 0px;
        font-weight: 600;
        color: #1F7D67;
        font-size: 4.5vw;

        &.importance {
            color: #0073BA;
        }
    }
`;