import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const Domains = ({label, type, domain, onSurvayDetailsInputChanges, domainOptions}) => {

    return (
        <DomainsStyled>
            <label htmlFor="0">{label}</label>
            <select id="domain-options" className="form-control" domain={domain} type={type} label={label} onChange={onSurvayDetailsInputChanges}>
                <option value="">Select a Value</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
            </select>
        </DomainsStyled>
    );
}

export default Domains;

const DomainsStyled = styled.div`
    margin-bottom: 10px;
    label {
        margin-bottom: 3px;
        font-size: 18px;
    }
    select {
        font-size: 14px;
    }

`;