import React from 'react';
import styled from 'styled-components';

const Home = () => {
    return (
        <HomeStyled className='Home inset'>
            <h1>Home</h1>
        </HomeStyled>
    );
}

export default Home;

const HomeStyled = styled.div`

`;