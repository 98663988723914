import React from 'react';
import styled from 'styled-components';

const Confident = ({label, type, domain, id, onSurvayDetailsInputChanges}) => {

    return (
        <ConfidentStyled>
            <label htmlFor={id}>{label}</label>
            <select className="form-control" id={id} domain={domain} type={type} onChange={onSurvayDetailsInputChanges}>
                <option value="">Select a Value</option>
                <option value="0">0 - Not at all confident</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100 - Extremely confident</option>
            </select>
        </ConfidentStyled>
    );
}

export default Confident;

const ConfidentStyled = styled.div`
    margin-bottom: 10px;
    label {
        margin-bottom: 3px;
        font-size: 18px;
    }
    select {
        font-size: 14px;
    }

`;