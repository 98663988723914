import React from 'react';
import styled from 'styled-components';
import download from 'downloadjs';
import { reqResp } from '../../../common/axios.js';

import data from './sample-input.json';

const ExportCSV = () => {

    const onClick = async () => {
        const resp = await reqResp(
            'post',
            `/exportData/csv`,
            data
        );
        download(resp, 'file.csv');
    }
    return (
        <ExportCSVStyled className='ExportCSV'>
            <h3>Survey CSV</h3>
            <button onClick={ onClick }>
                Generate Report
            </button>
        </ExportCSVStyled>
    );
}

export default ExportCSV;

const ExportCSVStyled = styled.section`

`;