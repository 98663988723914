import { ActionTypes } from '../actionTypes';

export const loginSuccess = (profile, isLoggedIn) => {
    return ({
        type: ActionTypes.LOGIN_SUCCESS,
        profile,
        isLoggedIn
    });
}

export const loginError = (err) => {
    return {
        type: ActionTypes.LOGIN_ERROR,
        err
    }
}

export const logoutSuccess = (profile, isLoggedIn) => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
        profile,
        isLoggedIn
    }
}

export function logout() {
    return dispatch => {
    //   localStorage.removeItem('id_token');
    //   localStorage.removeItem('profile');
      return dispatch(logoutSuccess());
    }
  }