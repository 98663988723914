import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { loginSuccess, logoutSuccess } from '../../../Redux/state/login/actions';
import { loadingSuccess } from '../../../Redux/state/loading/actions';
import axios from 'axios';
import { reqResp } from '../../../common/axios.js';

const loadingStyles = {
    margin: '250px auto',
    display: 'flex',
    justifyContent: 'center',
};

const Main = ({ children }) => {
    const loadingFlag = useSelector((state) => state.loading.loadingFlag);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    const AdminAllowedURLS = ['/', '/login', '/add-new-client', '/admin-list', '/client-list', '/logout' ];
    const ClientAllowedURLS = ['/', '/login', '/survey-list', '/admin-list', '/client-list', '/logout' ];
    const userAllowedURLS = ['/', '/login', '/survey-list', '/logout'];
    const publicAllowedURLS = ['/login'];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Public pages
        if ( !isLoggedIn && (publicAllowedURLS.includes(window.location.pathname))) {
            dispatch(loadingSuccess(true));
            return;
        }

        (async () => {
            try {
                const response = await reqResp('get', `/users/currentUser`);

                dispatch(loginSuccess(response, true));
                dispatch(loadingSuccess(true));

                const userProfile = response;
                const surveyToken = localStorage.getItem('surveyToken');
                const currentPath = window.location.pathname;
                var clientId = currentPath.split('/')[2];
                const isSurveyPathAllowed = currentPath.startsWith('/survey/');
                const isPathAllowed =
                    parseInt(clientId) === parseInt(userProfile.clientId);

                if (surveyToken) {
                    localStorage.removeItem('surveyToken');
                    axios
                        .put(
                            `${process.env.REACT_APP_API}/api/users/${userProfile.id}/update-assignment/${surveyToken}/survey`,
                            {}
                        )
                        .then((res) => {
                            navigate(`/survey/${surveyToken}`);
                            return res;
                        });
                } else if (userProfile.roleId === 1) {
                    if (
                        AdminAllowedURLS.indexOf(currentPath) > -1 ||
                        currentPath === '/client/' + clientId ||
                        isSurveyPathAllowed
                    ) {
                        return;
                    } else navigate('/');
                } else if (userProfile.roleId === 2) {
                    if (
                        ClientAllowedURLS.indexOf(currentPath) > -1 ||
                        (isPathAllowed &&
                            currentPath === '/client/' + clientId) ||
                        isSurveyPathAllowed
                    ) {
                        return;
                    } else navigate('/');
                } else if (userProfile.roleId === 3) {
                    if (
                        userAllowedURLS.indexOf(currentPath) > -1 ||
                        isSurveyPathAllowed
                    ) {
                        return;
                    } else navigate('/');
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.log('error', error);
                if (error.response.status === 440) {
                    navigate('/login');

                    dispatch(logoutSuccess({}, false));
                    dispatch(loadingSuccess(true));
                }
            }
        })();
    }, []);

    return loadingFlag ? (
        <MainStyled className="Main container-sm">{children}</MainStyled>
    ) : (
        <div style={loadingStyles}>
            <ReactLoading type="spin" color="#000" />
        </div>
    );
};

export default Main;

const MainStyled = styled.main``;
