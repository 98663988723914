import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import ResultsInline from '../../../Results/ResultsInline/ResultsInline.jsx';

const ResultsInlineExample = () => {
    return (
        <ResultsInlineExampleStyled className='ResultsInlineExample'>
            <ResultsInline confPercent={ 100 } impPercent={ 53 } />
        </ResultsInlineExampleStyled>
    );
}

export default ResultsInlineExample;

const ResultsInlineExampleStyled = styled.div`

`;