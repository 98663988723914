import React from 'react';
import styled from 'styled-components';

const Login = () => {
    const loginWithAuth0 = () => {
        location.href = `${process.env.REACT_APP_API}/login`;
    }
    return (
        <LoginStyled>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="grid">
                        <div className="grid-item large-span-6 medium-span-6 small-span-12">
                            <h2 className="typography-headline-reduced">
                            Login
                            </h2>
                            <div className="auth-container">
                                <p className="typography-body">
                                    Assessment Tool Project
                                </p>

                                <button
                                className="login-btn"
                                onClick={loginWithAuth0}>
                                    Sign in with Auth0
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoginStyled>
    );
}

export default Login;

const LoginStyled = styled.div`
    display: block;

    .page-wrapper {
        min-height: 500px;
        padding-top: 50px;

        h2 {
            font-size: 40px;
        }

        .auth-container {
            width: 30%;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            border: 1px solid #e8e8ed;
            border-radius: 18px;
        }


        .grid {
            margin: 20px;
            .login-btn {
                font-size: 17px;
                line-height: 1.17648;
                font-weight: 400;
                letter-spacing: -.022em;
                min-width: 26px;
                padding-left: 22px;
                padding-right: 22px;
                padding-top: 12px;
                padding-bottom: 12px;
                background: #e8e8ed;
                color: #000;
                cursor: pointer;
                border-radius: 980px;
            }
            .grid-item {
               width: 100%;
               text-align: center;

            }
         }
     }
`;