import React from 'react';
import styled from 'styled-components';

import ApiSimpleTest from './ApiSimpleTest/ApiSimpleTest.jsx';
import PostgreSQL from './PostgreSQL/PostgreSQL.jsx';
import ExportCSV from './ExportCSV/ExportCSV.jsx';

const TestApiCalls = () => {

    return (
        <TestApiCallsStyled className="TestApiCalls inset">
            <h2>Testing API Calls</h2>
            <ApiSimpleTest />
            <ExportCSV />
            <PostgreSQL />
        </TestApiCallsStyled>
    );
};

export default TestApiCalls;

const TestApiCallsStyled = styled.div`
    section {
        border: solid 1px #999;
        padding: 20px;
        margin: 30px 0px;
    }
`;
