import React, { useReducer } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from './ResultsContext/index.js';
import reducer from './ResultsContext/reducer.js';

/* Components ---------------------------*/
import SwConnectors from './SwConnectors/SwConnectors.jsx';
import Results from './Results/Results.jsx';

const ResultsComposite = ({results}) => {
    const [state, dispatch] = useReducer(reducer, results);

    return (
        <ResultsContext.Provider value={ {state, dispatch} } displayName={ `Results Composite` }>
            <ResultsCompositeStyled className='ResultsComposite'>
                <SwConnectors />
                <Results />
            </ResultsCompositeStyled>
        </ResultsContext.Provider>
    );
}

export default ResultsComposite;

const ResultsCompositeStyled = styled.div`
    position: relative;
`;