import React from 'react';
import styled from 'styled-components';

const Message = ({message}) => {
    return (
        <MessageStyled className='Message'>
            {
                (message.type !== 'GENERAL') &&
                <><b>{ message.type }:</b>{ ' ' }</>
            }
            <span dangerouslySetInnerHTML={ { __html: message.label } } />{ ' ' }
            <a href={ message.link } target="_blank">{ message.link }</a>
        </MessageStyled>
    );
}

export default Message;

const MessageStyled = styled.li`
    margin-bottom: 5px;
`;