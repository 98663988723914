import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import axios from 'axios';


const ResultPage = () => {
    const { surveyId } = useParams();
    const userProfile = useSelector((state) => state.login.profile)
    const [surveyUsers, setSurveyUsers] = useState([])
    
    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${userProfile.id}`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setUserDetails(data)
            console.log('userDetails', data)
        });
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${userProfile.clientId}/survey/${surveyId}/all`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setSurveyUsers(data)
            console.log('userDetails', data)
        });
    }, [])

    const viewResult = (e, subId, createdBy) => {
        e.preventDefault()
        window.location.href = `/survey/${subId}/user/${createdBy}/result`
    }
    return (
        <AllResultPageStyled className='ResultPage inset'>
            <h1>User Responces for { surveyUsers.name } </h1>
            <div className="card mb-4 mb-xl-0">
                            <div className="card-header">Survey Settings</div>
                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col" className="text-center">#</th>
                                        <th scope="col" className="text-center">Name</th>
                                        <th scope="col" className="text-center">email</th>
                                        <th scope="col" className="text-center">Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { surveyUsers.submissions && surveyUsers.submissions.map((sub, i) => {
                                            return (
                                                <tr>
                                                    <th scope="row" className="text-center">{i}</th>
                                                    <td className="text-center">{sub.submittedBy.name}</td>
                                                    <td className="text-center">
                                                    {sub.submittedBy.email}
                                                    </td>
                                                    <td className="text-center">
                                                        <button className="btn btn-primary" type="button" onClick={(e) => viewResult(e, sub.subId, sub.submittedBy.id)} >View Result</button>
                                                    </td> 
                                                </tr>
                                            )
                                        })}
                                </tbody> 
                            </table>
                        </div>    
        </AllResultPageStyled>
    );
}

export default ResultPage;

const AllResultPageStyled = styled.div`

`;