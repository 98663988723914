import React from 'react';
import styled from 'styled-components';

const Contact = () => {
    return (
        <ContactStyled className='Contact inset'>
            <h1>Contact</h1>
        </ContactStyled>
    );
}

export default Contact;

const ContactStyled = styled.div`

`;