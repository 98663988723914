import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux'

import axios from 'axios';

const loadingStyles = {
    margin: "250px auto",
    display: "flex",
    justifyContent: "center",
  }


const ClientDetails = () => {
    const [clientDetails, updateClientDetails] = useState({})
    const userProfile = useSelector((state) => state.login.profile)


    // Domain Config
    const [domain, setDomainInput] = useState('')
    const [domains, setDomains] = useState([])
    const [survey, setSurveyInput] = useState('')
    const [surveys, setSurveys] = useState([])

    // Questions Config
    const [question, setQuestionInput] = useState('')
    const [otherQuestion, setOtherQuestionInput] = useState('')
    const [questions, setQuestions] = useState([])

    // Questions / Domains Config
    const [questionDomains, setQuestionDomains] = useState([{
        domain: '',
        question: '',
        surveyDomainId: ''
    }])

    // Other Questions
    const [otherQuestions, setOtherQuestions] = useState([{
        question: '',
        surveyId: ''
    }])

    // Resources Config
    const [resources, setResources] = useState([{
        type: "", 
        label: "", 
        link: "",
        domain: '',
        surveyDomainsId: ''
    }])
    const resURLTypes = [
        'GENERAL',
        'HIGH',
        'MEDIUM',
        'LOW'         
    ]
    const [resourceURLType, setResourceURLType] = useState('')
    const [resourceLabel, setResourceLabel] = useState('')
    const [resourceLink, setResourceLink] = useState('')
    



    // Account Settings
    const [status, updateStatus] = useState('active')
    const [userLimit, updateUserLimit] = useState('active')

    // Set modal config
    const [domainsSaved, setDomainsSaved] = useState(false);
    const [showSurveys, setSurveysShow] = useState(false);
    const [surveysSaved, setSurveysSaved] = useState(false);
    const [showQuestions, setQuestionsShow] = useState(false);
    const [resourcesShow, setResourcesShow] = useState(false);
    const [showOtherQuestions, setOtherQuestionsShow] = useState(false);
    const [domainModelIndex, setDomainModelIndex] = useState()
    const [questionModelIndex, setQuestionModelIndex] = useState()
    const [resourcesModelIndex, setResourcesModelIndex] = useState()
    const [otherQuestionModelIndex, setOtherQuestionModelIndex] = useState()

    const handleDomainsClose = () => {
        setDomainModelIndex();
    }
    const handleDomainsShow = (domains, i) => {
        setDomainModelIndex(i);
        setDomains(domains)
    }

    const handleSurveysClose = () => setSurveysShow(false);
    const handleSurveysShow = () => setSurveysShow(true);

    const handleQuestionsClose = () => {
        setQuestionsShow(false);
        setQuestionModelIndex()
    }
    const handleQuestionsShow = (domains, i, survayId) => { 
        setQuestionsShow(true);
        setDomains(domains)
        setQuestionModelIndex(i)
        getQuestionDomains(survayId)
    }

    const handleResourcesClose = () => {
        setResourcesShow(false);
        setResourcesModelIndex()
    }
    const handleResourcesShow = (domains, i, survayId) => { 
        setResourcesShow(true);
        setDomains(domains)
        setResourcesModelIndex(i)
        getSurveyResources(survayId)
    }

    const handleOtherQuestionsShow = (i, survayId) => { setOtherQuestionsShow(true); setOtherQuestionModelIndex(i); getOtherQuestions(survayId); }
    const handleOtherQuestionsClose = () => { setOtherQuestionsShow(false); setOtherQuestionModelIndex() }

    // Account Details
    const [accountDetails, setAccountDetails] = useState({
        'organization': '',
        'phone_number': '',
        'first_name': '',
        'last_name': '',
        'email': '',
        'intro': ''
    });


    const { id } = useParams();

    const getSurveys = async () => {
        await axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/surveys`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setSurveys(data)
            if (data.length) {
                setSurveysSaved(true)
            }
        });
    }

    const getQuestionDomains = async (survayId) => {
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/domains-questions/${survayId}/survey`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setQuestionDomains(data)
        }
            );
    }

    const getSurveyResources = async (survayId) => {
        setResources([])
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/survey-domains/${survayId}/survey`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            console.log('data', data)
            data.forEach(element => {
                element.resources.forEach(ele => {
                    setResources((prevState) => ([
                        ...prevState,
                        {
                            type: ele.type, 
                            label: ele.label, 
                            link: ele.link,
                            domain: element.domain,
                            surveyDomainsId: element.id
                        }
                    ]))
                })
            });
            
        }
            );
    }

    const getOtherQuestions = async (survayId) => {
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/other-questions/${survayId}/survey`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setOtherQuestions(data)
        }
            );
    }

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API}/api/clients/${id}`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data.data
            updateClientDetails(data) 
            onStatusUpdate(data.status)
            updateUserLimit(data.user_limit)
            setAccountDetails(data)
        }
            );
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/domains`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setDomains(data)
            if (data.length) {
                setDomainsSaved(true)
            }
        });
        getSurveys()
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${id}/surveys`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setSurveys(data)
            if (data.length) {
                setSurveysSaved(true)
            }
        });
      }, []);

      

    const onStatusUpdate = (status) => {
        updateStatus(status)
    }

    const onUserLimitUpdate = (value) => {
        updateUserLimit(value)
    }

    const onAccountDetailsInputChanges = (event) => {
        setAccountDetails((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value
         }));
    }

    const onSettingUpdate = (e) => {
        e.preventDefault()
        axios.put(
            `${process.env.REACT_APP_API}/api/clients/${id}`,
            { 
                status,
                user_limit: userLimit
            }
        ).then(res => res);
    }

    const updateDomainInput = (e) => {
        const domain = e.target.value;
        setDomainInput(domain);
    }   

    const updateSurveyInput = (e) => {
        const survey = e.target.value;
        setSurveyInput(survey);
    }   

    const updateQuestionInput = (e) => {
        const question = e.target.value;
        setQuestionInput(question);
    } 

    const updateResourceLabel = (e) => {
        const label = e.target.value;
        setResourceLabel(label);
    } 
    const updateResourceLink = (e) => {
        const link = e.target.value;
        setResourceLink(link);
    } 

    const updateOtherQuestionInput = (e) => {
        const question = e.target.value;
        setOtherQuestionInput(question);
    } 

    const addDomains = (e) => {
        setDomains((prevState) => ([
            ...prevState,
            {
                domain
            }
        ]));
        setDomainInput('');
    }

    const addSurveys = (e) => {
        setSurveys((prevState) => ([
            ...prevState,
            {
                name: survey,
                domainQuestions: []
            }
        ]));
        setSurveyInput('');

    }
    const handleSaveSurveys = (e) => {
        e.preventDefault()
        axios.put(
            `${process.env.REACT_APP_API}/api/users/${id}/surveys`,
            { 
                surveys
            }
        ).then(res => { 
            setSurveysShow(false)
            setSurveysSaved(true)
            getSurveys().then(res => res).catch(err => console.log(err))
            return res;
        }).catch(() => setSurveysSaved(false));
    }

    const handleSaveDomains = (e, surveyId) => {
        console.log('surveyId', surveyId)
        e.preventDefault()
        axios.put(
            `${process.env.REACT_APP_API}/api/users/${id}/domains/${surveyId}/survey`,
            { 
                domains
            }
        ).then(res => { 
            setDomainModelIndex()
            setDomainsSaved(true)
            setDomainInput('');
            setDomains([])
            getSurveys().then(res => res).catch(err => console.log(err))
            return res;
        }).catch(() => setDomainsSaved(false));
    }

    const handleSaveResources = (e, surveyId) => {
        console.log('surveyId', surveyId)
        e.preventDefault()
        axios.put(
            `${process.env.REACT_APP_API}/api/users/${id}/survey-domains/${surveyId}/survey`,
            { 
                payload: resources.filter(item => item.surveyDomainsId !== '')
            }
        ).then(res => { 
            setResourcesModelIndex()
            return res;
        }).catch(() => setDomainsSaved(false));
    }

    const handleSaveQuestionDomains = (e, surveyId) => {
        e.preventDefault()
        const payload = questionDomains.map(item => ({
            domain: item.domain,
            question: item.question,
            surveyDomainId: item.surveyDomainId,
            surveyId,
        })).filter(item => item.question !== '')
        axios.post(
            `${process.env.REACT_APP_API}/api/users/${id}/domains-questions/${surveyId}/survey`,
            { 
                payload
            }
        ).then(res => { 
            setQuestionsShow(false)
            setQuestionModelIndex()
            getSurveys().then(res => res).catch(err => console.log(err))
            return res;
        });
    }
    const handleSaveOtherQuestions = (e, surveyId) => {
        e.preventDefault()
        const payload = otherQuestions.map(item => ({
            question: item.question,
            surveyId
        })).filter(item => item.question !== '')
        axios.post(
            `${process.env.REACT_APP_API}/api/users/${id}/other-questions/${surveyId}/survey`,
            { 
                payload
            }
        ).then(res => { 
            setOtherQuestionsShow(false)
            setOtherQuestionModelIndex()
            getSurveys().then(res => res).catch(err => console.log(err))
            return res;
        });
    }
    const addOtherQuestions = (event) => {
        console.log('otherQuestion', otherQuestion)
        setOtherQuestions((prevState) => ([
            ...prevState,
            { question: otherQuestion }
        ]));
        console.log('otherQuestion', otherQuestions)
    }
    const onDropdownSelect = (eventKey, event) => {
        const surveyDomainId = event.target.id;
        event.preventDefault();
        event.persist();
        event.stopPropagation();
        setDomainInput(eventKey);
        setQuestions((prevState) => ([
            ...prevState,
            question
        ]));
        setQuestionDomains((prevState) => ([...prevState, {
            question,
            domain: eventKey,
            surveyDomainId
        }]))

        console.log(123, questionDomains)
    }

    const onDropdownResURLTypesSelect = (eventKey, event) => {
        setResourceURLType(eventKey)
    }
    const onDropdownDomainSelect = (eventKey, event) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();

        setResources((prevState) => ([
            ...prevState,
            {
                label: resourceLabel,
                link: resourceLink,
                type: resourceURLType,
                domain: eventKey,
                surveyDomainsId: event.target.id
            }
        ]));
        setDomainInput('');
        setResourceLink('');
        setResourceLabel('');
        setResourceURLType('')

    }

    const addQuestions = (e) => {
        e.preventDefault()
        window.location.href = `/client/${id}/survay`
    }

    const viewSurvey = (e, token) => {
        e.preventDefault()
        window.location.href = `/survey/${token}`
    }

    const createSurvay = (e) => {
        e.preventDefault()
        window.location.href = `/client/${id}/survay`
    }

    const onAccountDetailsUpdate = (e) => {
        e.preventDefault()
        axios.put(
            `${process.env.REACT_APP_API}/api/clients/${id}`,
            { 
                ...accountDetails
            }
        ).then(res => res);
    }

    return (
        Object.keys(clientDetails).length > 0 ? (
        <ClientDetailsStyled className='Home inset'>
            <div className="container-xl mt-4">
                <div className="row">
                    <div className="col-xl-8">
                        <div className="card mb-4">
                            <div className="card-header">Account Details</div>
                            <div className="card-body">
                                <form onSubmit={onAccountDetailsUpdate}>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-12">
                                            <label className="small mb-1" htmlFor="inputOrgName">Organization name</label>
                                            <input className="form-control" id="organization" type="text" placeholder="Enter your organization name" value={accountDetails.organization} onChange={(e) => onAccountDetailsInputChanges(e)} />
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-6">
                                            <label className="small mb-1" htmlFor="inputFirstName">First name</label>
                                            <input className="form-control" id="first_name" type="text" placeholder="Enter your first name" value={accountDetails.first_name}  onChange={(e) => onAccountDetailsInputChanges(e)} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small mb-1" htmlFor="inputLastName">Last name</label>
                                            <input className="form-control" id="last_name" type="text" placeholder="Enter your last name" value={accountDetails.last_name}  onChange={(e) => onAccountDetailsInputChanges(e)} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="small mb-1" htmlFor="inputEmailAddress">Email address</label>
                                        <input className="form-control" id="email" type="email" placeholder="Enter your email address" value={accountDetails.email}  onChange={(e) => onAccountDetailsInputChanges(e)} />
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-12">
                                            <label className="small mb-1" htmlFor="inputPhone">Phone number</label>
                                            <input className="form-control" id="phone_number" type="tel" placeholder="Enter your phone number" value={accountDetails.phone_number}  onChange={(e) => onAccountDetailsInputChanges(e)} />
                                        </div>
                                    </div>
                                    <div className="row gx-3 mb-3">
                                        <div className="col-md-12">
                                            <label className="small mb-1" htmlFor="inputPhone">Introduction</label>
                                            <textarea className="form-control" id="intro" type="tel" placeholder="Enter your introduction" value={accountDetails.intro}  onChange={(e) => onAccountDetailsInputChanges(e)} />
                                        </div>
                                    </div>
                                    <button className="btn btn-primary" type="submit">Save changes</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card mb-xl-0">
                            <div className="card-header">Account Settings</div>
                            <form onSubmit={onSettingUpdate} >
                                <div className="card-body text-left">
                                    <div className="small font-italic text-muted mb-4">
                                        <span>User Limit: </span> <input className="form-control" id="userLimit" type="text" name="userLimit" placeholder="Enter User Limit" value={userLimit} onChange={(e) => onUserLimitUpdate(e.target.value)} disabled={userProfile.roleId !== 1} />
                                    </div>
                                    <div className="small font-italic text-muted mb-4"><span>Account Status: </span> 
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value='active' checked={status === 'active'} onChange={(e) => onStatusUpdate(e.target.value)} disabled={userProfile.roleId !== 1} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value='inActive' checked={status === 'inActive'} onChange={(e) => onStatusUpdate(e.target.value)} disabled={userProfile.roleId !== 1} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">InActive</label>
                                        </div> 
                                    </div>
                                    <button className="btn btn-primary w-100" type="submit" disabled={userProfile.roleId !== 1}>Update</button>
                                </div>
                            </form>
                        </div>
                        <Button variant="btn btn-primary btn-lg margin" onClick={handleSurveysShow} disabled={userProfile.roleId !== 1}>
                                Add Survey
                            </Button>

                            <Modal show={showSurveys} onHide={handleSurveysClose}>
                                <Modal.Header closeButton>
                                <Modal.Title>Survey List:</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="form-row align-items-center">
                                        <div className="col-auto">
                                            <input className="w-100 margin" type="text" value={survey} onChange={updateSurveyInput} placeholder="Add Surveys" />
                                        </div>
                                        <div className="col-auto">
                                            <input type="button" onClick={addSurveys} value="Add" style={{ marginTop: '5px' }} />
                                        </div>
                                    </div>
                                    <ol>
                                        { surveys.map(survey => {
                                            return (
                                                <li 
                                                className="todo-list" 
                                                style={{ margin: '5px', color: 'green', fontSize: '20px' }}>{survey.name}</li>
                                            )
                                        })}
                                    </ol>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleSurveysClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSaveSurveys}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                    </div>
                    { surveys.length ? (
                        <div className="col-xl-12">
                        <div className="card mb-4 mb-xl-0">
                            <div className="card-header">Survey Settings</div>
                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col" className="text-center">#</th>
                                        <th scope="col" className="text-center">Survey</th>
                                        <th scope="col" className="text-center">Domains</th>
                                        <th scope="col" className="text-center">Questions</th>
                                        <th scope="col" className="text-center">Other Questions</th>
                                        <th scope="col" className="text-center">Resources</th>
                                        <th scope="col" className="text-center">Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { surveys.map((survey, i) => {
                                            return (
                                                <tr>
                                                    <th scope="row" className="text-center">{i}</th>
                                                    <td className="text-center">{survey.name}</td>
                                                    <td className="text-center">
                                                        <Button variant="btn btn-success" id={survey.id} onClick={() => handleDomainsShow(survey.domains, i)} disabled={userProfile.roleId !== 1}>
                                                            +
                                                        </Button>

                                                        <Modal show={i === domainModelIndex} onHide={() => handleDomainsClose(i)}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Domain List:</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="form-row align-items-center">
                                                                    <div className="col-auto">
                                                                        <input className="w-100 margin" type="text" value={domain} onChange={updateDomainInput} placeholder="Add Domains" />
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <input type="button" onClick={addDomains} value="Add" style={{ marginTop: '5px' }} />
                                                                    </div>
                                                                </div>
                                                                <ol>
                                                                    { domains.map(item => {
                                                                        return (
                                                                            <li 
                                                                            className="todo-list" 
                                                                            style={{ margin: '5px', color: 'green', fontSize: '20px' }}>{item.domain}</li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <Button variant="secondary" onClick={() => handleDomainsClose(i)}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={(e) => handleSaveDomains(e, survey.id)}>
                                                                Save Changes
                                                            </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button variant="btn btn-success" onClick={() => handleQuestionsShow(survey.domains, i, survey.id)} disabled={survey.domains <= 0 || userProfile.roleId !== 1}>
                                                            +
                                                        </Button>

                                                        <Modal style={{'--bs-modal-width': '75%'}} show={i === questionModelIndex} onHide={handleQuestionsClose}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Question List</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={question} onChange={updateQuestionInput} aria-label="Text input with dropdown button" />
                                                                    <Dropdown onSelect={onDropdownSelect}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                            { domain ? domain : 'Domains'}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            { domains.map( item => {
                                                                                return <Dropdown.Item eventKey={item.domain} id={item.id} >{item.domain}</Dropdown.Item>
                                                                            })}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <ol>
                                                                    { questionDomains.map(questionDomain => {
                                                                        return (
                                                                            (questionDomain.question || questionDomain.domain) &&
                                                                            <li className="todo-list" 
                                                                            style={{ margin: '5px', color: 'green', fontSize: '20px' }}>{questionDomain.question} - {questionDomain.domain}</li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleQuestionsClose}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={(e) => handleSaveQuestionDomains(e, survey.id)}>
                                                                Save Changes
                                                            </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button variant="btn btn-success" onClick={() => handleOtherQuestionsShow(i, survey.id)} disabled={survey.domains <= 0 || survey.domainQuestions <= 0 || userProfile.roleId !== 1}>
                                                            +
                                                        </Button>

                                                        <Modal style={{'--bs-modal-width': '75%'}} show={i === otherQuestionModelIndex} onHide={handleOtherQuestionsClose}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Other Question List</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={otherQuestion} onChange={updateOtherQuestionInput} aria-label="Text input with dropdown button" />
                                                                    <button className="btn btn-primary" type="button" onClick={() => addOtherQuestions(survey.id)}>Add</button>
                                                                    {/* <Dropdown onSelect={onDropdownSelect}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                            { domain ? domain : 'Domains'}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            { domains.map( item => {
                                                                                return <Dropdown.Item eventKey={item.domain} id={item.id} >{item.domain}</Dropdown.Item>
                                                                            })}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown> */}
                                                                </div>
                                                                <ol>
                                                                    { otherQuestions.map(otherQuestions => {
                                                                        return (
                                                                            (otherQuestions.question) &&
                                                                            <li className="todo-list" 
                                                                            style={{ margin: '5px', color: 'green', fontSize: '20px' }}>{otherQuestions.question}</li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleOtherQuestionsClose}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={(e) => handleSaveOtherQuestions(e, survey.id)}>
                                                                Save Changes
                                                            </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button variant="btn btn-success" onClick={() => handleResourcesShow(survey.domains, i, survey.id)} disabled={survey.domains <= 0 || survey.domainQuestions <= 0 || userProfile.roleId !== 1}>
                                                            +
                                                        </Button>

                                                        <Modal style={{'--bs-modal-width': '75%'}} show={i === resourcesModelIndex} onHide={handleResourcesClose}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Resources List</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <div className="input-group" style={{'flex-direction': 'column', 'justify-content': 'right'}}>
                                                                    <input type="text" className="form-control w-100" value={resourceLabel} onChange={updateResourceLabel} placeholder='Label*' aria-label="Text input with dropdown button" />
                                                                    <input type="text" className="form-control w-100" value={resourceLink} onChange={updateResourceLink} placeholder='Link*' aria-label="Text input with dropdown button" style={{'margin-top': '10px'}} />
                                                                    <Dropdown onSelect={onDropdownResURLTypesSelect}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{'margin-top': '10px'}}>
                                                                            { resourceURLType ? resourceURLType : 'Link Type'}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            { resURLTypes.map( item => {
                                                                                return <Dropdown.Item eventKey={item} id={item} >{item}</Dropdown.Item>
                                                                            })}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    <Dropdown onSelect={onDropdownDomainSelect}>
                                                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{'margin-top': '10px'}}>
                                                                            { domain ? domain : 'Domains'}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            { domains.map( item => {
                                                                                return <Dropdown.Item eventKey={item.domain} id={item.id} >{item.domain}</Dropdown.Item>
                                                                            })}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <ol>
                                                                    { resources.map(item => {
                                                                        return (
                                                                            (item.label || item.link) &&
                                                                            <li className="todo-list" 
                                                                            style={{ margin: '5px', color: 'green', fontSize: '20px' }}>{item.label} - {item.link} - {item.domain}</li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <Button variant="secondary" onClick={handleResourcesClose}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={(e) => handleSaveResources(e, survey.id)}>
                                                                Save Changes
                                                            </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </td>
                                                    <td className="text-center">
                                                        <button className="btn btn-primary" type="button" onClick={(e) => viewSurvey(e, survey.token)} disabled={survey.domainQuestions.length <= 0}>View Survey</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>                        
                    </div>
                    ) : ''}
                    
                    <div className="col-xl-4">
                        <div className="card mt-3">
                            
                        </div>
                    </div>
                </div>
            </div> 
        </ClientDetailsStyled> )
        : <div style={loadingStyles}><ReactLoading type='spin' color="#000" /></div>
    );
}

export default ClientDetails;

const ClientDetailsStyled = styled.div`

    .card-header {
        background-color: #0a4643;
        color: white;
    }
    .card-body {
        span {
            font-weight: bold;
            margin-bottom: 10px;
            display:block;
        }
    }

    .col-xl-4 {
        .mt-3 {
            border: none;
        }
    }

    button, input {
        &.margin {
            margin: 20px 0;
            width: 100%;
        }
    }

    .todo-list {
        margin: 5px;
        color: green;
        font-size: 20px;
    }

    .table {
        margin-bottom: 0px
    }

`;