import React from 'react';
import styled from 'styled-components';

const Questions = ({label, type, domain, id, onSurvayDetailsInputChanges}) => {

    return (
        <QuestionsStyled>
            <label htmlFor="0">{label}</label>
            <select className="form-control" id={id} domain={domain} type={type} label={label} onChange={onSurvayDetailsInputChanges}>
                <option value="">Select a value</option> 
                <option value="Most relevant to my strength. Because of my personal 
                    strength it is most likely that I would do this.">Most relevant to my strength. Because of my personal 
                    strength it is most likely that I would do this.</option>
                <option value="Relevant to my strength. Because of my 
                    personal strength it is somewhat likely that I would do 
                    this.">Relevant to my strength. Because of my 
                    personal strength it is somewhat likely that I would do 
                    this.
                </option>
                <option value="Irrelevant. This is not relevant to my personal ">Irrelevant. This is not relevant to my personal 
                strength.
                </option>
                <option value="Negatively relevant to my strength.
                    Because of my personal strength, it is somewhat 
                    unlikely that I would do this.">Negatively relevant to my strength.
                    Because of my personal strength, it is somewhat 
                    unlikely that I would do this.
                </option>
                <option value="Most negatively relevant to my strength 
                    of my personal strength, it is most unlikely that I would 
                    do this.">Most negatively relevant to my strength 
                    of my personal strength, it is most unlikely that I would 
                    do this.
                </option>
            </select>
        </QuestionsStyled>
    );
}

export default Questions;

const QuestionsStyled = styled.div`
    margin-bottom: 10px;
    label {
        margin-bottom: 3px;
        font-size: 18px;
    }
    select {
        font-size: 14px;
    }

`;