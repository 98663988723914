import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from 'axios';


/* Components ---------------------------*/
import ResultsInline from '../../../Results/ResultsInline/ResultsInline.jsx';

const ResultsInlineGraph = () => {

    const { confPercent, impPercent } = useParams();

    return (
        <ResultsInlineGraphStyled className='ResultsInlineGraph'>
            <ResultsInline confPercent={ confPercent } impPercent={ impPercent } />
        </ResultsInlineGraphStyled>
    );
}

export default ResultsInlineGraph;

const ResultsInlineGraphStyled = styled.div`

`;