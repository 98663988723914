import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux'
import axios from 'axios';
import moment from 'moment';

const SurveyList = () => {
    const [surveyList, setSurveyList] = useState([])
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
    const userProfile = useSelector((state) => state.login.profile)

    useEffect(() => {
        if (isLoggedIn) {
            const userId = userProfile.roleId === 2 ? userProfile.clientId : userProfile.id
            axios.get(
                `${process.env.REACT_APP_API}/api/users/${userId}/assesment-taker-survey-list`,
                {}
            ).then(res => { 
                const data = res.data
                setSurveyList(data.surveys)
                return res;
            });
        }
    }, [])
    return (
        <SurveyListStyled className='Survey List inset'>
            <h1>{ userProfile.roleId === 2 ? 'My Survey Result List' : userProfile.roleId === 3 ? 'My Survey List' : 'Survey List'}</h1>
            { surveyList.length ? (
                <div className="row">
                    { surveyList.map(survey => {
                        return (
                            <div className="col-sm-4 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">{survey.name}</h5>
                                        <p className="card-text"><span>Created At: </span> {moment(survey.createdAt).format('MMM Do YY')}</p>
                                        { userProfile.roleId === 2 && (
                                            <p className="card-text"><span>Responses: </span> { survey.submissions.length}</p>
                                        )}
                                        { userProfile.roleId === 2 && userProfile.clientId === survey.userId ? 
                                        (
                                            <a href={'/survey/' + survey.token + '/all-result'} className="btn btn-secondary" className={survey.submissions.length === 0 ? 'disabled' : ''}>View Survey Results</a>
                                        ) 
                                        : userProfile.roleId === 3 && survey.submissions.length ?
                                        <a href={'/survey/' + survey.id + '/result'} className="btn btn-secondary">View Submission</a> 
                                        : <a href={'/survey/' + survey.token} className="btn btn-secondary">Take Survey</a> }
                                    </div>
                                </div>
                            </div>   
                            )
                        })
                    }
                </div>
            ) : userProfile.roleId === 2 ? 'No one responded yet to your Active Survey' : userProfile.roleId === 3 ? 'Survey List is Empty' : ''}
        </SurveyListStyled>
    );
}

export default SurveyList;

const SurveyListStyled = styled.div`
    h1 {
        margin: auto auto 20px auto;
    }

    .row {
        .btn {
            background-color: #0a4643;
            color: white;

            &:hover {
                color: red;
                font-weight: bold;
                background-color: #fff;
            }
        }
        .card-text {
            text-transform: capitalize;
            margin-bottom: 5px;
            span {
                font-weight: bold;
            }
        }
        a {
            &.disabled {
                pointer-events: none;
                cursor: default;
                background-color: lightgray
            }
        }
    }
`;