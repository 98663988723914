import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';


const ClientList = () => {
    const [clientList, updateClientList] = useState([])

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API}/api/clients/`,
            { withCredentials: true }
        ).then(res => updateClientList(res.data.data));
      }, []);

    return (
        <ClientListStyled className='Home inset'>
            <h1>Client List</h1>
            { clientList.length ? (
                <div className="row">
                    { clientList.map(client => {
                        return (
                            <div className="col-sm-4 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">{client.first_name} {client.last_name}</h5>
                                        <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                        <p className="card-text"><span>Status: </span> {client.status}</p>
                                        <p className="card-text"><span>User Limit: </span>{client.user_limit}</p>
                                        <a href={'/client/' + client.id} className="btn btn-secondary">Manage Account</a>
                                    </div>
                                </div>
                            </div>   
                            )
                        })
                    }
                </div>
            ) : (<h4>Client list is empty. Add now</h4>)}
        </ClientListStyled>
    );
}

export default ClientList;

const ClientListStyled = styled.div`

    h1 {
        margin: auto auto 20px auto;
    }

    .row {
        .btn {
            background-color: #0a4643;
            color: white;

            &:hover {
                color: red;
                font-weight: bold;
                background-color: #fff;
            }
        }
        .card-text {
            text-transform: capitalize;
            span {
                font-weight: bold;
            }
        }
    }

`;