import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Arrow = ({
    title = 'Previous',
    direction = 'left',
    fill = '#0073BA',
    size = '1.5vw',
    length = '2vw',
    onClick,
    btnProps,
}) => {
    const theClassName = classnames({
        Arrow: true,
        [`arrow-${direction}`]: true,
    });

    return (
        <ArrowStyled
            className={theClassName}
            onClick={onClick}
            fill={fill}
            size={size}
            length={length}
            {...btnProps}
        >
            <span className="title">{title}</span>
        </ArrowStyled>
    );
};

export default Arrow;

const ArrowStyled = styled.button`
    .title {
        font-size: 0px;
    }

    background-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;

    &.arrow-left {
        width: 0;
        height: 0;
        border-top: ${({ size }) => size} solid transparent;
        border-bottom: ${({ size }) => size} solid transparent;
        border-right: ${({ length }) => length} solid ${({ fill }) => fill};

        &:disabled {
            border-right: ${({ length }) => length} solid #B1B1B1;
        }
    }

    &.arrow-right {
        width: 0;
        height: 0;
        border-top: ${({ size }) => size} solid transparent;
        border-bottom: ${({ size }) => size} solid transparent;
        border-left: ${({ length }) => length} solid ${({ fill }) => fill};

        &:disabled {
            border-left: ${({ length }) => length} solid #B1B1B1;
        }
    }

    &.arrow-up {
        width: 0;
        height: 0;
        border-left: ${({ size }) => size} solid transparent;
        border-right: ${({ size }) => size} solid transparent;
        border-bottom: ${({ length }) => length} solid ${({ fill }) => fill};

        &:disabled {
            border-bottom: ${({ length }) => length} solid #B1B1B1;
        }
    }

    &.arrow-down {
        width: 0;
        height: 0;
        border-left: ${({ size }) => size} solid transparent;
        border-right: ${({ size }) => size} solid transparent;
        border-top: ${({ length }) => length} solid ${({ fill }) => fill};

        &:disabled {
            border-top: ${({ length }) => length} solid #B1B1B1;
        }
    }
`;
