import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';


const AdminList = () => {
    const [adminList, updateAdminList] = useState([])

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API}/api/users/admin/`,
            { withCredentials: true }
        ).then(res => updateAdminList(res.data.data));
      }, []);

    return (
        <AdminListStyled className='Home inset'>
            <h1>Admin List</h1>
            <div className="row">
                { adminList.map(admin => {
                    return (
                        <div className="col-sm-4 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{admin.firstName} {admin.lastName}</h5>
                                    <p className="card-text"><span>Status: </span> {admin.status}</p>
                                    <p className="card-text"><span>email: </span> {admin.email}</p>
                                    {/* <a href={'/admin/' + admin.id} className="btn btn-secondary" disabled>Manage Account</a> */}
                                </div>
                            </div>
                        </div>   
                        )
                    })
                }
            </div>
        </AdminListStyled>
    );
}

export default AdminList;

const AdminListStyled = styled.div`

    h1 {
        margin: auto auto 20px auto;
    }

    .row {
        .btn {
            background-color: #0a4643;
            color: white;

            &:hover {
                color: red;
                font-weight: bold;
                background-color: #fff;
            }
        }
        .card-text {
            text-transform: capitalize;
            margin-bottom: 5px;
            span {
                font-weight: bold;
            }
        }
    }

`;