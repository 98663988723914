import React from 'react';
import { Routes, Route } from 'react-router-dom';

/* Layout ---------------------------*/
import LayoutPrimary from './Layout/Primary/Layout.jsx';
import LayoutPublic from './Layout/Public/Layout.jsx';
import LayoutBare from './Layout/Bare/Layout.jsx';

/* Components ---------------------------*/
import Home from './Pages/Home.jsx';
import Contact from './Pages/Contact.jsx';
import Login from './Pages/Login.jsx';
import ClientList from './Pages/ClientList';
import AdminList from './Pages/AdminList';
import ClientDetails from './Pages/ClientDetails';
import CreateSurvay from './Pages/CreateSurvay';
import ResultPage from './Pages/ResultPage';
import AllResultPage from './Pages/AllResultPage';
import AddNewClient from './Pages/AddNewClient';
import SurveyList from './Pages/SurveyList';

/* Testing API Calls and Postgres ---------------------------*/
import TestApiCalls from './TestApiCalls/TestApiCalls';

/* Results Graphic Tests and Samples ---------------------------*/
import ResultsExamples from './PagesPublic/ResultsExamples/ResultsExamples.jsx';
import ResultsCompositeExample from './PagesPublic/ResultsExamples/ResultsCompositeExample/ResultsCompositeExample.jsx';
import ResultsCompositeGraph from './PagesPublic/Results/ResultsComposite/ResultsCompositeGraph.jsx';
import ResultsInlineExample from './PagesPublic/ResultsExamples/ResultsInlineExample/ResultsInlineExample.jsx';
import ResultsInlineGraph from './PagesPublic/Results/ResultsInline/ResultsInline.jsx';

const UI = () => {
    return (
        <Routes>
            {/* Public Routes :: Secondary layout - No Main Authentication Logic */}
            <Route element={<LayoutPublic />} >
                <Route element={ <TestApiCalls />} path="test" />
            </Route>

            {/* Public Routes :: No Header / Bare */}
            <Route element={<LayoutBare />} >
                <Route element={ <ResultsExamples />} path="results-example" />
                <Route element={ <ResultsCompositeExample />} path="results-composite-example" />
                <Route element={ <ResultsCompositeGraph />} path="/:report/:createdBy/:surveyId/results-composite" />
                <Route element={ <ResultsInlineGraph />} path="/:confPercent/:impPercent/results-inline" />
                <Route element={ <ResultsInlineExample />} path="results-inline-example" />
            </Route>

            {/* Authenticated Routes :: Primary Layout */}
            <Route element={<LayoutPrimary />} >
                <Route element={ <Home /> } path='' />
                <Route element={ <Login /> } path='login' />
                <Route element={ <AddNewClient /> } path='add-new-client' />
                <Route element={ <ClientList /> } path='client-list' />
                <Route element={ <AdminList /> } path='admin-list' />
                <Route element={ <ClientDetails /> } path='client/:id' />
                <Route element={ <CreateSurvay /> } path='survey/:token' />
                <Route element={ <ResultPage /> } path='survey/:surveyId/result' />
                <Route element={ <ResultPage /> } path='survey/:surveyId/user/:createdBy/result' />
                <Route element={ <AllResultPage /> } path='survey/:surveyId/all-result' />
                <Route element={ <Contact /> } path='contact' />
                <Route element={ <SurveyList />} path="survey-list" />
            </Route>
        </Routes>
    );
}

export default UI;