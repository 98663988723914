import React, { useContext } from 'react';
import styled from 'styled-components';

/* Scripts ---------------------------*/
import { styleConnector } from '../../Common/utils.js';

/* Context ---------------------------*/
import ResultsContext from '../ResultsContext/index.js';

/* SVGs ---------------------------*/
import { ReactComponent as SwConnectorsSVG } from './sw-connectors.svg';

const SwConnectors = () => {
    const { state: { domains } } = useContext(ResultsContext);

    return (
        <SwConnectorsStyled className='SwConnectors' domains={ domains }>
            <SwConnectorsSVG />
        </SwConnectorsStyled>
    );
}

export default SwConnectors;

const SwConnectorsStyled = styled.div`
    /* Anchor to center */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* Responsive Scaling */
    width: 70vw;

    /* needs to sit behind Results */
    z-index: 1;

    /* Connector Styles */
    /* Row 1 */
    ${ ({domains}) => styleConnector(domains,  domains[0] ? domains[0].id : undefined, '#sw-r1-d1') }
    ${ ({domains}) => styleConnector(domains, domains[1] ? domains[1].id : undefined, '#sw-r1-d2') }
    ${ ({domains}) => styleConnector(domains, domains[2] ? domains[2].id : undefined, '#sw-r1-d3') }

    /* Row 2 */
    ${ ({domains}) => styleConnector(domains, domains[3] ? domains[3].id : undefined, '#sw-r2-d1') }
    ${ ({domains}) => styleConnector(domains, domains[4] ? domains[4].id : undefined, '#sw-r2-d2') }

    /* Row 2 */
    ${ ({domains}) => styleConnector(domains, domains[5] ? domains[5].id : undefined, '#sw-r3-d1') }
    ${ ({domains}) => styleConnector(domains, domains[6] ? domains[6].id : undefined, '#sw-r3-d2') }
    ${ ({domains}) => styleConnector(domains, domains[7] ? domains[7].id : undefined, '#sw-r3-d3') }
`;