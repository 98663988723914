import React, { useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from '../../../../ResultsContext/index.js';
import { percOptionsNext, percOptionsPrev } from '../../../../ResultsContext/actions.js';

/* Components ---------------------------*/
import Arrow from './Arrow.jsx';

const Nav = ({ id, percents }) => {
    const {
        currPercOptionIdx,
        percOptions,
    } = percents;

    if (percOptions.length < 2) { return '' }

    const { dispatch } = useContext(ResultsContext);

    const handlePrev = (e) => {
        e.stopPropagation()
        dispatch(percOptionsPrev(id));
    }

    const handleNext = (e) => {
        e.stopPropagation()
        dispatch(percOptionsNext(id));
    }

    const prevDisabled = currPercOptionIdx === 0;
    const nextDisabled = currPercOptionIdx === percOptions.length - 1;

    return (
        <NavStyled className='Nav'>
            <Arrow direction={ 'left' } onClick={ handlePrev } btnProps={{disabled: prevDisabled}} />
            <h3>{ currPercOptionIdx + 1  }</h3>
            <Arrow direction={ 'right' } onClick={ handleNext } btnProps={{disabled: nextDisabled}} />
        </NavStyled>
    );
}

export default Nav;

const NavStyled = styled.nav`

    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255, .8);

    width: 24%;
    margin: 0px auto;

    h3 {
        font-size: 3.7vw;
        font-weight: 600;
        padding: 5px;
        margin: 0px;
        line-height: 100%;
        width: 4vw;
        text-align: center;
    }
`;