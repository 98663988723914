import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <LayoutStyled className='Layout'>
            <Outlet />
        </LayoutStyled>
    );
}

export default Layout;

const LayoutStyled = styled.div`

`;