import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import axios from 'axios';
import GraphResult from '../helper/GraphResult'
import ScalingIFrame from 'UI/Results/ScalingIFrame';

const ResultPage = () => {
    const { surveyId, createdBy } = useParams();
    const userProfile = useSelector((state) => state.login.profile)
    const [userDetails, setUserDetails] = useState({})
    const [SurveyDetails, setSurveyDetails] = useState({})
    const [createdByUserId, setCreatedByUserId] = useState('')
    const [categoryWithHighConfidence, setcategoryWithHighConfidence] = useState('')
    const [categoryWithLowConfidence, setCategoryWithLowConfidence] = useState('')
    const [highestImportant, setHighestImportant] = useState('')
    const [highestConfident, setHighestConfident] = useState('')
    const [lowestConfident, setLowestConfident] = useState('')
    const [categoryNotRatedOne, setCategoryNotRatedOne] = useState('')
    const [categoryRatedOneInRelationToStrengthWithHighestImportance, setCategoryRatedOneInRelationToStrengthWithHighestImportance] = useState('')
    const [categoryRatedOneInRelationToWeakness, setCategoryRatedOneInRelationToWeakness] = useState('')
    
    const [categoryRatedLowInRelationToStrength, setCategoryRatedLowInRelationToStrength] = useState('')
    const [categoryRatedLowInRelationToWeakness, setCategoryRatedLowInRelationToWeakness] = useState('')
    
    
    const [importantButWithLessConfident, setImportantButWithLessConfident] = useState([])

    let createdByUser = userProfile.roleId === 2 ? createdBy : userProfile.id
    const callBack = (createdByUser) => {
        setCreatedByUserId(createdByUser)
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${createdByUser}`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setUserDetails(data)
            console.log('userDetails', data)
        });
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${createdByUser}/survey/${surveyId}`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data

            const confident = data.confident
            const important = data.important
            const strength = data.important
            const weakness = data.weakness
            const domain = data.domain
            const domainCopy = domain.filter((item, i) => i !== 0)
            const domainName = domainCopy.map((item, i) => item.domain)
            setCategoryNotRatedOne(domainName.join(', '))

            const highestDomain = domain[domain.length - 1]
            const highestImportant = important[important.length - 1]
            const highestConfident = confident[confident.length - 1]
            const lowestConfident = confident[0]
            const lowDomain = domain[0]
            const domainWithHightConfident = confident.find(item => item.domainsQuestions.domain = highestDomain.domain )
            const domainWithLowtConfident = confident.find(item => item.domainsQuestions.domain = lowDomain.domain )

            const questionRatedFive = strength.find(item => item.option && item.option.includes('Most negatively relevant'))
            const questionRatedFiveWeakness = weakness.find(item => item.option && item.option.includes('Most negatively relevant'))
            const questionRatedOne = strength.find(item => item.option && item.option.includes('Most relevant & likely'))
            const weaknessRatedOne = weakness.find(item => item.option && item.option.includes('Most relevant & likely'))
            if (questionRatedFive) {
                setCategoryRatedLowInRelationToStrength(questionRatedFive.domainsQuestions.domain)
            }
            if (questionRatedFiveWeakness) {
                setCategoryRatedLowInRelationToWeakness(questionRatedFiveWeakness.domainsQuestions.domain)
            }
            if (questionRatedOne) {
                setCategoryRatedOneInRelationToStrengthWithHighestImportance(questionRatedOne.domainsQuestions.domain)
            }
            if (weaknessRatedOne) {
                setCategoryRatedOneInRelationToWeakness(weaknessRatedOne.domainsQuestions.domain)
            }
            setHighestConfident(highestConfident && parseInt(highestConfident.percentage) > 50 ? highestConfident.domainsQuestions.domain : '')
            setLowestConfident(lowestConfident ? lowestConfident.domainsQuestions.domain : '')
            setHighestImportant(highestImportant.domainsQuestions.domain)
            setcategoryWithHighConfidence(parseInt(domainWithHightConfident.percentage) >= 50 ? domainWithHightConfident.domainsQuestions.domain : '')
            setCategoryWithLowConfidence(parseInt(domainWithLowtConfident.percentage) >= 50 ? domainWithLowtConfident.domainsQuestions.domain : '')

            const importantButWithLessConfident = important.filter(item => confident.some(confItem => confItem.domainQuestionsId === item.domainQuestionsId && parseInt(confItem.percentage) < parseInt(item.scale)))
            setImportantButWithLessConfident(importantButWithLessConfident)
            console.log('importantButWithLessConfident', importantButWithLessConfident)


            setSurveyDetails(data)
            console.log('userDetails', data)
        });
    }
    useEffect(() => {
        if (!createdByUser) {
            console.log('surveyId', surveyId)
            axios.get(
                `${process.env.REACT_APP_API}/api/users/${surveyId}/survey-submission`,
                { withCredentials: true }
            ).then(res => { 
                const data = res.data
                callBack(data.createdBy)
            });
        } else {
            callBack(createdByUser)
        }
        
    }, [])
    const getPercentage = (c, domainQuestionsId) => {
        return c.find(item => item.domainQuestionsId === domainQuestionsId).percentage
    }
    const getScale = (i, domainQuestionsId) => {
        return i.find(item => item.domainQuestionsId === domainQuestionsId).scale
    }
    const updateStrokeDasharray = (c, i, domainQuestionsId, cId, iId) => {
        const cValue = c.find(item => item.domainQuestionsId === domainQuestionsId).percentage
        const iValue = i.find(item => item.domainQuestionsId === domainQuestionsId).scale

        setTimeout(() => {
            let c1 = document.getElementById(cId);
            let c2 = document.getElementById(iId);
            if (parseInt(cValue) === 100) {
                c1.style.strokeDasharray = `${(parseInt(cValue) + 1)} 99`;
            } else {
                c1.style.strokeDasharray = `${parseInt(cValue)} 100`;
            }
            if (parseInt(iValue) === 100) {
                c2.style.strokeDasharray = `${parseInt(iValue) + 1} 99`;
            } else {
                c2.style.strokeDasharray = `${parseInt(iValue)} 100`;
            }
            c1.style.stroke = `rgba(31, 124, 102, 1)`;
            c2.style.stroke = `rgba(37, 113, 187, 1)`;
        }, 500)
    }
    if (importantButWithLessConfident.length) {
        return (
            <ResultPageStyled className='ResultPage inset'>
                <div class="strength-section">
                    <h2>Strength Report:</h2>
                    { SurveyDetails.selfDeclaredStrength && (
                        <p>You see yourself as someone whose strength is: {`${SurveyDetails.selfDeclaredStrength[0].value} ${SurveyDetails.selfDeclaredStrength[2].value}`}</p>
                    )}
                    {categoryWithHighConfidence && (
                         <p>You believe this helps you successfully engage with with your {categoryWithHighConfidence}, and to a lesser 
                            degree with (Categories rated 1 in relation to strength and LOWER Confidence but still 
                            50 or above).
                        </p>
                    ) }
                    { categoryWithLowConfidence && (
                        <p>Your strength is related to {categoryWithLowConfidence}, but you lack confidence here. </p>
                    )}
                    { categoryNotRatedOne && (
                        <p>Your strength does not seem to impact with your {categoryNotRatedOne}. </p>
                    )}
                    { categoryRatedLowInRelationToStrength && SurveyDetails.selfDeclaredStrength && (
                        <p>Your strength: {`${SurveyDetails.selfDeclaredStrength[0].value} ${SurveyDetails.selfDeclaredStrength[2].value}`} means you are not likely to successfully do things related to 
                        your {categoryRatedLowInRelationToStrength}. However, you are still fairly confident 
                        you could do these things. </p>
                    )}
                    { categoryRatedOneInRelationToStrengthWithHighestImportance && highestImportant && (
                        <p>Considering things most related to your strength, your {highestImportant} are most important to you. </p>
                    )}
                    <div className="legend-section">
                        <div className="content">
                            <b>Legend</b>
                            <p className="green-circle"><span /> Outer Ring: Confidence</p>
                            <p className="blue-circle"><span /> Inner Ring: Importance </p>
                            <p className="light-blue-circle"><span /> Blue = Positively Related </p>
                            <p className="gray-circle"><span /> Gray = Negatively Related </p>
                            <p className="white-circle"><span /> No Rings/White = No Relation </p>
                        </div>
                    </div>
                    <ScalingIFrame src={ `/strength/${createdByUserId}/${surveyId}/results-composite` } />
                   
                </div>

                <div className="divider"></div>

                <h2>Detailed Report:</h2>
                <p>There were some specific situations that
                were important to you, but you had a lower
                level of confidence to succeed.</p>
                <p>Being aware of these can help you to im-prove and address them in the future.
                    These are areas you can target for your
                future development:</p>
                <div className="list-item">
                    { importantButWithLessConfident.map((item, i) => {
                        return (
                            <div className="detailed-report-container">
                                <li>{ item.domainsQuestions ? item.domainsQuestions.question : '' }</li>
                                <ScalingIFrame src={ `/${getPercentage(SurveyDetails.confident, item.domainQuestionsId)}/${getScale(SurveyDetails.important, item.domainQuestionsId)}/results-inline` } aspectRatio='9/4.5' />
                            </div>
                        )
                    })}
                </div>
                
                <div className="divider"></div>

                <div class="weakness-section">
                    <h2>Weakness Report:</h2>
                    { SurveyDetails.selfDeclaredWeakness && (
                        <p>You see your weakness as: {`${SurveyDetails.selfDeclaredWeakness[0].value} ${SurveyDetails.selfDeclaredWeakness[2].value}`}</p>
                    )}
                    { categoryRatedOneInRelationToWeakness && highestConfident && (
                        <p>
                            However, you believe this means you are more likely to engage with your 
                            {categoryRatedOneInRelationToWeakness}, and you are confident you 
                            can do well with your {highestConfident}.
                        </p>
                    )}
                    { categoryRatedOneInRelationToWeakness && lowestConfident && (
                        <p>
                            You are less confident you will be successful when engaging with your {lowestConfident}.
                        </p>
                    )}
                    { categoryNotRatedOne && (
                        <p>Your weakness does not seem to impact with how manage your {categoryNotRatedOne}. </p>
                    )}
                    { categoryRatedLowInRelationToWeakness && SurveyDetails.selfDeclaredWeakness && (
                        <p>Your weakness: {`${SurveyDetails.selfDeclaredWeakness[0].value} ${SurveyDetails.selfDeclaredWeakness[2].value}`} means you are not likely to successfully do things related to 
                        your {categoryRatedLowInRelationToWeakness}. However, you are still fairly confident 
                        you could do these things. </p>
                    )}
                     { categoryRatedOneInRelationToWeakness && highestImportant && (
                        <p>Considering things most related to your weakness: {categoryRatedOneInRelationToWeakness}, the things most important to you are: {highestImportant}. </p>
                    )}
                    <div className="legend-section">
                        <div className="content">
                            <b>Legend</b>
                            <p className="green-circle"><span /> Outer Ring: Confidence</p>
                            <p className="blue-circle"><span /> Inner Ring: Importance </p>
                            <p className="light-blue-circle"><span /> Blue = Positively Related </p>
                            <p className="gray-circle"><span /> Gray = Negatively Related </p>
                            <p className="white-circle"><span /> No Rings/White = No Relation </p>
                        </div>
                    </div>
                    <ScalingIFrame src={ `/weakness/${createdByUserId}/${surveyId}/results-composite` } />
                    
                </div>
                
            </ResultPageStyled>
        );
    } else return ''
    
}

export default ResultPage;

const ResultPageStyled = styled.div`

h2 {
    text-align: center;
    margin-bottom: 40px; 
}

p {
    margin-bottom: 0px;
    text-align: center;
}

div.strength-section {
    margin-top: 20px;
}

div.list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-top: 25px;
    text-align: left;
    flex-direction: column;
}
div.list-item .detailed-report-container {
    width: 40%;
    margin: 0 auto;
}
div.list-item .detailed-report-container li {
    margin-bottom: 15px;
    margin-top: 25px;
}
.main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px;
}

.main svg {
    overflow: initial;
}

.legend-section {
    background-color: #DFEED2;
    padding: 20px;
    margin-top: 20px;
}
.legend-section b { 
    margin-bottom: 10px;
}
.legend-section p {
    margin-bottom: 1px;
    display: flex;
}
.legend-section p span {
    border-radius: 50%;
    width: 20px;
    display: flex;
    height: 20px;
    margin-right: 10px;
}
.legend-section p.light-blue-circle, .legend-section p.gray-circle, .legend-section p.white-circle {
    align-items: center;
}
.legend-section p.green-circle {
    margin-top: 10px;
}
.legend-section p.green-circle span { border: 5px solid rgba(31, 124, 102, 1); }
.legend-section p.blue-circle span { border: 5px solid rgba(37, 113, 187, 1); }
.legend-section p.light-blue-circle span { background-color: #42bdef; border: 1px solid rgba(37, 113, 187, 1); width: 40px; border-radius: 20%; height: 10px; align-items: center;  }
.legend-section p.gray-circle span { background-color: #979797; border: 1px solid rgba(37, 113, 187, 1); width: 40px; border-radius: 20%; height: 10px; align-items: center; }
.legend-section p.white-circle span { background-color: #fff; border: 1px solid rgba(37, 113, 187, 1); width: 40px; border-radius: 20%; height: 10px; align-items: center; }
.main .right {
    .c-color, .i-color {
        font-weight: 600
    }
    .c-color {
        color: rgba(31, 124, 102, 1)
    }
    .i-color {
        color: rgba(37, 113, 187, 1)
    }
}
.circle-one {
    width: 120px;
    margin-top: 20px;
}
.circle-two {
    width: 65px;
    margin-left: -92px;
    margin-bottom: -17px;
}

circle[id^=c], circle[id^=i] {
    stroke-width: 4;
    fill: none;
    stroke-dasharray: 0 100;
    stroke-dashoffset: -200;
    transition: stroke-dasharray 3s linear;
  }
div.divider {
    width: 100%;
    height: 20px;
    background-color: #42bdef;
    margin: 40px auto 20px auto;
}
.weakness-section p, .strength-section p {
    text-align: left;
}

`;