import { ActionTypes } from '../actionTypes';

const defaultState = {
    isLoggedIn: false,
    profile: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                profile: action.profile,
            };
        case ActionTypes.LOGIN_ERROR:
            return {
                ...state,
                err: action.err,
            };
        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                profile: action.profile,
            };
        default:
            return state;
    }
}