import React from 'react';
import styled from 'styled-components';

const Icon = ({icon}) => {
    return (
        <IconStyled className='Icon'>
            <img src={ icon } />
        </IconStyled>
    );
}

export default Icon;

const IconStyled = styled.div`
    /* Anchor to center */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* Responsive Scaling */
    width: 7vw;

    img {
        width: 100%;
    }
`;