import React from 'react';
import styled from 'styled-components';
import { reqResp } from '../../../common/axios.js';

const ApiSimpleTest = () => {

    const onClick = async () => {
        const resp = await reqResp(
            'get',
            `/sample`
        );
        console.log('Sample resp', resp);
    }

    return (
        <ApiSimpleTestStyled className='ApiSimpleTest'>
            <h3>API Simple Test</h3>
            <button onClick={ onClick }>
                Get Sample
            </button>
        </ApiSimpleTestStyled>
    );
}

export default ApiSimpleTest;

const ApiSimpleTestStyled = styled.section`

`;