import React, { useContext } from 'react';
import styled from 'styled-components';

/* Context ---------------------------*/
import ResultsContext from '../../../../ResultsContext/index.js';
import { popUpClose } from '../../../../ResultsContext/actions.js';

/* Components ---------------------------*/
import CloseButton from 'UI/common/CloseButton.jsx';
import Message from './Message';

const TextBox = ({id, messages}) => {

    const { dispatch } = useContext(ResultsContext);

    const handleClose = (e) => {
        e.stopPropagation();
        dispatch(popUpClose(id));
    }

    return (
        <TextBoxStyled className='TextBox'>
            <CloseButton onClick={ handleClose } />
            <ul className="text">
                {
                    messages.map((message,idx) => <Message key={ idx } message={ message } />)
                }
            </ul>
        </TextBoxStyled>
    );
}

export default TextBox;

const TextBoxStyled = styled.div`

    /* Anchor to center */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -71%);

    border: solid 1px #0073BA;
    border-radius: 10px;

    /* background-color: rgba(255,255,255,.95); */
    /* background-color: #EDC55C; */
    background-color: rgba(237, 196, 92, 0.95);

    width: 24vw;
    height: 19vw;
    padding: 10px;

    .text {
        width: 100%;
        height: 100%;
        font-size: 1.3vw;
        overflow-y: auto;

        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    .CloseButton {
        background-color: white;
        position: absolute;
        top: -1.5vw;
        right: -1vw;
    }
`;