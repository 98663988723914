import { combineReducers } from 'redux';

// Reducers
import defaultState from '../state/default/reducers.js';
import loginState from '../state/login/reducers';
import LoadingState from '../state/loading/reducers';

// Combine All Reducers
const combinedReducers = combineReducers({
    default: defaultState,
    login: loginState,
    loading: LoadingState
});

export default combinedReducers;
