import { css } from 'styled-components';

export const getDomainById = (domains, id) => {
    return domains.find((d) => d.id === id);
}

export const getCurrentPercOption = (percents) => {
    const {
        currPercOptionIdx,
        percOptions,
    } = percents;

    return percOptions.find((po, idx) => idx === currPercOptionIdx);
}

export const styleConnector = (domains, domainId=1, selector="#sw-r3-d1") => {

    let display = 'none';
    let fill = '#42BDEE';

    const domain = getDomainById(domains, domainId);

    if (domain) {
        const { percents } = domain;
        const currentPercOption = getCurrentPercOption(percents);
        fill = currentPercOption ? currentPercOption.stemColor : '#fff';
        display = currentPercOption ? (currentPercOption.showStem) ? 'inline': 'none' : 'none';
    }

        


    return css`
        ${selector} {
            display: ${display};
            fill: ${fill};
        }
    `
}