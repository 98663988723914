import React from 'react';
import styled from 'styled-components';

const GraphResult = ({ domains, strength, weakness, selfDeclaredStrength, selfDeclaredWeakness, report, confident, important }) => {

    const filterSelfDeclaredWeakness = selfDeclaredWeakness.filter(item => item.value.toLowerCase() !== 'no' && item.value.toLowerCase() !== 'yes' )
    const filterSelfDeclaredStrength = selfDeclaredStrength.filter(item => item.value.toLowerCase() !== 'no' && item.value.toLowerCase() !== 'yes' )

    const getConnection = (domain) => {
        const type = report === 'strength' ? strength : weakness
        const currentStrengthOrWeakness = type.find(item => item.domainsQuestions.domain === domain)
        if (currentStrengthOrWeakness.option.includes('Most relevant & likely')) {
            return 'positive'
        } else if (currentStrengthOrWeakness.option.includes('Most negatively relevant')) {
            return 'negative'
        } else {
            return 'noRelation'
        }
    }

    const updateStrokeDasharray = (domain, cId, iId, pC, pI, report) => {
        let domainQuestionsId = null
        if (report === 'strength') {
            domainQuestionsId = strength.find(item => item.domainsQuestions.domain === domain).domainQuestionsId
        } else {
            domainQuestionsId = weakness.find(item => item.domainsQuestions.domain === domain).domainQuestionsId
        }

        if (!domainQuestionsId) {
            throw new Error('domainQuestionsId is missing')
        }

        const cValue = confident.find(item => item.domainQuestionsId === domainQuestionsId)?.percentage
        const iValue = important.find(item => item.domainQuestionsId === domainQuestionsId)?.scale

        if (cId && iId) {
            setTimeout(() => {
                let c1 = document.getElementById(cId);
                let c2 = document.getElementById(iId);
                let p1 = document.getElementById(pC);
                let p2 = document.getElementById(pI);
                const cValueInt = parseInt(cValue)
                const iValueInt = parseInt(iValue)
                if (cValueInt >= 0) {
                    if (cValueInt === 100) {
                        c1.style.strokeDasharray = `${(cValueInt + 1)} 99`;
                        p1.setAttribute('x', 300)
                        
                    } else {
                        c1.style.strokeDasharray = `${cValueInt} 100`;
                    }
                    if(cValueInt !== 0) {
                        p1.innerHTML = `${cValueInt}%`
                    }
                }
                if (iValueInt >= 0) {
                    if (iValueInt === 100) {
                        c2.style.strokeDasharray = `${iValueInt + 1} 99`;
                        p2.setAttribute('y', 58)
                        p2.setAttribute('x', 235)
                    } else {
                        c2.style.strokeDasharray = `${iValueInt} 100`;
                    }
                    if(iValueInt !== 0) {
                        p2.innerHTML = `${iValueInt}%`
                    }
                }
                c1.style.stroke = `rgba(31, 124, 102, 1)`;
                c2.style.stroke = `rgba(37, 113, 187, 1)`;
            }, 500)
        }
        
    }
    return (
        <GraphResultStyled>
            <div id="container">
                { domains && domains.length && domains.length > 4 && domains.map((domain, i) => {
                        return (
                            <>
                                { i === 4 && (
                                    <svg id="domain-circle">
                                        <circle cx="50%" cy="50%" r="120" stroke="#2470ba" strokeWidth="4" fill="white"/>
        
                                        <text className="heading font" x="50%" y="40%" fontFamily="Verdana" fontSize="22" fill="black" dominantBaseline="middle" textAnchor="middle" stroke-width="1" stroke="#1e1919">
                                        { report === 'strength' ? 'Your Strength' : 'Your Weakness' }:
                                        </text>
                                        <text className="font" x="50%" y="50%" fontFamily="Verdana" fontSize="17" fill="black" dominantBaseline="middle" textAnchor="middle" stroke="#1e1919">
                                        { report === 'strength' ? filterSelfDeclaredStrength.length && filterSelfDeclaredStrength[0].value : filterSelfDeclaredWeakness.length && filterSelfDeclaredWeakness[0].value }/
                                        </text>
                                        <text className="font" x="50%" y="58%" fontFamily="Verdana" fontSize="17" fill="black" dominantBaseline="middle" textAnchor="middle" stroke="#1e1919">
                                        { report === 'strength' ? filterSelfDeclaredStrength.length && filterSelfDeclaredStrength[1].value : filterSelfDeclaredWeakness.length && filterSelfDeclaredWeakness[1].value }
                                        </text>
                                    </svg>
                                )}
                                <svg id="domain-circle">
                                    <>
                                    { (getConnection(domain.domain) === 'negative' || getConnection(domain.domain) === 'positive') && (
                                        <>
                                            <circle id={report === 'strength' ? `rC${i}` : `rCW${i}`} cx="155" cy="185" r="100" stroke="#207e67" pathLength="100" transform="rotate(-90 155 126)" transformOrigin="center" />
                                            <circle id={report === 'strength' ? `rI${i}` : `rIW${i}`} cx="155" cy="185" r="52" stroke="#2470ba"  pathLength="100" transform="rotate(-90 155 126)" transformOrigin="center" />
                                            <text id={report === 'strength' ? `pC${i}` : `pCW${i}`} x="245" y="26" fontSize="25" fill="#2571bb" dominantBaseline="middle" textAnchor="middle" font-weight="500"></text>
                                            <text id={report === 'strength' ? `pI${i}` : `pIW${i}`} x="245" y="76" fontSize="25" fill="#2571bb" dominantBaseline="middle" textAnchor="middle" font-weight="500"></text>
                                        </>
                                    )}
                                    
                                    { updateStrokeDasharray(domain.domain, report === 'strength' ? `rC${i}` : `rCW${i}`, report === 'strength' ? `rI${i}` : `rIW${i}`, report === 'strength' ? `pC${i}` : `pCW${i}`, report === 'strength' ? `pI${i}` : `pIW${i}`, report) }
                                    {
                                        domain.domain === 'Belonging' && (
                                            <image x="41%" y="36%" width="75" height="70" xlinkHref="/assets/icons/belonging.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Concentration' && (
                                            <image x="41%" y="35%" width="75" height="70" xlinkHref="/assets/icons/concentration.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Physical Health' && (
                                            <image x="41%" y="35%" width="75" height="70" xlinkHref="/assets/icons/physical-health.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Commuting' && (
                                            <image x="42%" y="34%" width="75" height="70" xlinkHref="/assets/icons/commuting.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Family' && (
                                            <image x="41%" y="34%" width="75" height="70" xlinkHref="/assets/icons/family.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Mental Health' && (
                                            <image x="41%" y="34%" width="75" height="70" xlinkHref="/assets/icons/mental-health.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Academics' && (
                                            <image x="41%" y="36%" width="75" height="70" xlinkHref="/assets/icons/Academics.svg"></image>
                                        )
                                    }
                                    {
                                        domain.domain === 'Finances' && (
                                            <image x="41%" y="36%" width="75" height="70" xlinkHref="/assets/icons/finances.svg"></image>
                                        )
                                    }
                                     { getConnection(domain.domain) === 'positive' ? (
                                         <rect x="25%" y="75%" width="50%" height="40" rx="20" fill="#42bdef" />
                                     ) : getConnection(domain.domain) === 'negative' ? <rect x="25%" y="75%" width="50%" height="40" rx="20" fill="#c0c0c0" /> : <rect x="25%" y="75%" width="50%" height="40" rx="20" fill="#fff" />}
                                    
                                    <text x="50%" y="83%" dominantBaseline="middle" textAnchor="middle" fontSize="20" stroke-width="1" stroke="#000000">{ domain.domain }</text>
                                    </>
                                </svg>
                                <svg id="line">
                                   
                                    { i === 0 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="20%" y2="26%"/>
                                    )}
                                    {i === 1 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="50%" y2="25%"/>
                                    )}
                                    {i === 2 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="75%" y2="29%"/>
                                    )}
                                    {i === 3 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="25%" y2="50%"/>
                                    )}
                                    {i === 4 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="75%" y2="50%"/>
                                    )}
                                    {i === 5 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="23%" y2="80%"/>
                                    )}
                                    {i === 6 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="50%" y2="70%"/>
                                    )}
                                    {i === 7 && (
                                        <line className={(getConnection(domain.domain) === 'positive' ? "positive" : getConnection(domain.domain) === 'negative' ? "negative" : "noRelation")} x1="50%" y1="50%" x2="77%" y2="77%"/>
                                    )}
                                </svg>
                            </>
                        )
                })
                
                }
            </div>
        </GraphResultStyled>
    );
}

export default GraphResult;

const GraphResultStyled = styled.div`
    margin: 20px 0;
    #container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }
    #container svg {
        width: 33.3%;
    }
    #container svg rect {
        stroke-width: 2px;
        stroke: rgb(0,0,0);
    }
    #container svg text.font {
        cursor: text;
        opacity: .4;
        pointer-events: auto;
        -webkit-user-select: text;
        user-select: text;
    }
    #container svg text.heading.font {
        opacity: 0.8;
    }
    #domain-circle {
        display: inline-flex;
        height: 251px;
    }
    circle[id^=r]  {
        stroke-width: 15;
        fill: none;
        stroke-dasharray: 0 100;
        z-index: 20;
        stroke-dashoffset: -200;
        transition: stroke-dasharray 3s linear;
    }

    #line {
        width: 100% !important;
        height: 100%;
        z-index: -1;
        position: absolute;
    }
    #line .noRelation {
        display: none;
    }
    #line .positive {
        stroke: #2470ba;
        stroke-width:5;
    }
    #line .negative {
        stroke: #c0c0c0;
        stroke-width:5;
    }
    #line line {
        stroke:#000;
        stroke-width:2
    }
    @media screen and (max-width: 1200px) {
        #domain-circle {
          
        }
      }
`;