import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import ConfidenceCircle from '../Common/Circles/ConfidenceCircle.jsx';
import ImportanceCircle from '../Common/Circles/ImportanceCircle.jsx';

const Circles = ({confPercent, impPercent}) => {
    return (
        <CirclesStyled className='Circles'>
            <img className='mortar' src="/assets/img/square-transparent-pixel.png" aria-hidden="true" />
            <ConfidenceCircle percentage={ confPercent } />
            <ImportanceCircle percentage={ impPercent } />
        </CirclesStyled>
    );
}

export default Circles;

const CirclesStyled = styled.div`
    position: relative;

    .mortar {
        width: 100%;
    }

    .ConfidenceCircle {
        /* Anchor to center */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        /* Responsive Scaling */
        width: 44vw;
    }
    .ImportanceCircle {
        /* Anchor to center */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        /* Responsive Scaling */
        width: 25vw;
    }

`;