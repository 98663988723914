import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import Circles from './Circles.jsx';
import Percentages from './Percentages.jsx';

const ResultsInline = (props) => {
    return (
        <ResultsInlineStyled className='ResultsInline'>
            <div className='ri-col ri-left'>
                <Circles { ...props } />
            </div>
            <div className='ri-col ri-right'>
                <Percentages { ...props } />
            </div>
        </ResultsInlineStyled>
    );
}

export default ResultsInline;

const ResultsInlineStyled = styled.div`
    display: flex;

    .ri-col {
        width: 50%;

        &.ri-right {
            padding: 3vw;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
`;