import React from 'react';
import styled from 'styled-components';
import { reqResp } from '../../../common/axios.js';

const PostgreSQL = () => {

    const handleSharks = async () => {
        const resp = await reqResp(
            'get',
            `/sharks`
        );
        console.log('Sharks resp', resp);
    }

    const handleGetCurrentUser = async () => {
        const resp = await reqResp(
            'get',
            `/users/currentUser`,
        );
        console.log('Get CurrentUSer resp', resp);
    }

    return (
        <PostgreSQLStyled className='PostgreSQL'>
            <h3>PostgreSQL Tests</h3>
            <section>
                <h4>Sharks</h4>
                <button onClick={ handleSharks }>
                    Get Sharks
                </button>
            </section>
            <section>
                <h4>Current User</h4>
                <p>Will only work when user is authenticated, or locally when Yaseen adds fake user for development.</p>
                <button onClick={ handleGetCurrentUser }>
                    Get Current User
                </button>
            </section>
        </PostgreSQLStyled>
    );
}

export default PostgreSQL;

const PostgreSQLStyled = styled.section`

`;