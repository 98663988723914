import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import ScalingIFrame from '../../Results/ScalingIFrame.jsx';

const ResultsExamples = () => {
    return (
        <ResultsExamplesStyled className='ResultsExamples'>
            <h2>Results Example Page</h2>

            <h3>Composite</h3>
            <a target='composite' href="/results-composite-example">Isolate Composite</a>
            <ScalingIFrame src={ '/results-composite-example' } />

            <h3>Inline</h3>
            <a target='inline' href="/results-inline-example">Isolate Inline</a>
            <ScalingIFrame src={ '/results-inline-example' } aspectRatio='9/4.5' />

        </ResultsExamplesStyled>
    );
}

export default ResultsExamples;

const ResultsExamplesStyled = styled.div`

    h3 {
        margin-top: 50px;
    }
`;