import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from 'axios';


/* Components ---------------------------*/
import ResultsComposite from '../../../Results/ResultsComposite/ResultsComposite.jsx';

/* Scripts ---------------------------*/
// import { resultsData } from './resultsCompositeExampleData.js';

const ResultsCompositeGraph = () => {
    const { surveyId, createdBy, report } = useParams();
    const userProfile = useSelector((state) => state.login.profile)
    const [resultsData, setResultsData] = useState({
        strengthWeakness: {},
        domains: []
    })

    const getConfident = (confident=[], domainQuestionsId) => {
        return parseInt(confident.find(item => item.domainQuestionsId === domainQuestionsId).percentage)
    }
    const getImportant = (important=[], domainQuestionsId) => {
        return parseInt(important.find(item => item.domainQuestionsId === domainQuestionsId).scale)
    }
    const getIconPath = (domain) => {
        switch(domain.toLowerCase()) {
            case 'academics':
                return '/assets/icons/domains/client1/academics.svg';
            case 'belonging':
                return '/assets/icons/domains/client1/belonging.svg';
            case 'commuting':
                return '/assets/icons/domains/client1/commuting.svg';
            case 'concentration':
                return '/assets/icons/domains/client1/concentration.svg';
            case 'mental health':
                return '/assets/icons/domains/client1/mental-health.svg';
            case 'family':
                return '/assets/icons/domains/client1/family.svg';
            case 'finances':
                return '/assets/icons/domains/client1/finances.svg';
            case 'physical health':
                return '/assets/icons/domains/client1/physical-health.svg';
        }
    }

    const getPercOptions = (domain, strength, weakness, confident, important) => {
        const type = report === 'strength' ? strength : weakness
        const currentStrengthOrWeakness = type.filter(item => item.domainsQuestions.domain === domain)
        console.log('currentStrengthOrWeakness', currentStrengthOrWeakness)
        return currentStrengthOrWeakness.map(element => {
            if (element.option.includes('Most relevant') || element.option.includes('Most negatively')) {
                return {
                    confidence: getConfident(confident, element.domainQuestionsId),  
                    importance: getImportant(important, element.domainQuestionsId),    
                    showStem: true, 
                    stemColor: element.option.includes('Most relevant') ? '#42BDEE' : '#eeeeee', 
                    titleBgColor: element.option.includes('Most relevant') ? '#42BDEE' : '#eeeeee'
                }
            } else {
                return null
            }
        }).filter(item => item);


    }

    const getConnection = (domain, strength, weakness) => {
        const type = report === 'strength' ? strength : weakness
        const currentStrengthOrWeakness = type.find(item => item.domainsQuestions.domain === domain)
        if (currentStrengthOrWeakness.option.includes('Most relevant')) {
            return 'positive'
        } else if (currentStrengthOrWeakness.option.includes('Most negatively')) {
            return 'negative'
        } else {
            return 'noRelation'
        }
    }

    useEffect(() => {
        console.log('createdBy', createdBy)
        axios.get(
            `${process.env.REACT_APP_API}/api/users/${createdBy}/survey/${surveyId}`,
            { withCredentials: true }
        ).then(async res => { 
            const data = res.data
            console.log('data', data)
            const confident = await data.confident
            const important = await data.important
            const domainData = await data.domain
            const weakness = await data.weakness
            const strength = await data.strength
            const selfDeclaredWeakness = await data.selfDeclaredWeakness
            const selfDeclaredStrength = await data.selfDeclaredStrength
            console.log('domains', data.domain)

            const filterSelfDeclaredWeakness = selfDeclaredWeakness.find(item => item.value.toLowerCase() !== 'no' && item.value.toLowerCase() !== 'yes' )
            const filterSelfDeclaredStrength = selfDeclaredStrength.find(item => item.value.toLowerCase() !== 'no' && item.value.toLowerCase() !== 'yes' )
            console.log('filterSelfDeclaredWeakness', filterSelfDeclaredWeakness)
            console.log('filterSelfDeclaredStrength', filterSelfDeclaredStrength)

            const domains =  domainData.map(item => ({
                id: item.id,
                title: item.domain,
                icon: getIconPath(item.domain),
                positiveOrNegativeConnection: getConnection(item.domain, strength, weakness),
                percents: {
                    // confidence: getConfident(confident, item.domain),
                    // importance: getImportant(important, item.domain),
                    currPercOptionIdx: 0,
                    percOptions: getPercOptions(item.domain, strength, weakness, confident, important)
                    // percOptions: [
                    //     { confidence: 100,  importance: 100,    showStem: true, stemColor: "#42BDEE", titleBgColor: "#42BDEE" },
                    //     { confidence: 75,   importance: 25,     showStem: true, stemColor: "#eeeeee", titleBgColor: "#eeeeee" },
                    //     { confidence: 25,   importance: 75,     showStem: true, stemColor: "#996633", titleBgColor: "#996633" },
                    // ]
                },
                popUp: {
                    show: false,
                    currentMessage: 0,
                    messages: [
                        { type: "GENERAL", label: "<b>Lorem 1</b> ipsum doler set almighty...", link: "http://apple.com", },
                        { type: "GENERAL", label: "<b>Lorem 2</b> ipsum dolor sit amet, consectetur adipisicing elit. Laborum similique dicta vero iure aliquam beatae eaque, ea cupiditate, ex esse sapiente, dolorum maxime est. Nemo qui odit vel consequuntur dolorem..", link: "http://google.com", },
                        { type: "HIGH", label: "<b>Lorem 3</b> ipsum doler set almighty...", link: "https://auth0.com/", },
                        { type: "HIGH", label: "<b>Lorem 5</b> ipsum doler set almighty...", link: "http://apple.com", },
                    ]
                }

            }))
            // 
            const results = {
                strengthWeakness: {
                    title: report === 'strength' ? "Your Strength" : "Your Weakness",
                    userChosen: report === 'strength' ? filterSelfDeclaredStrength.value : filterSelfDeclaredWeakness.value,
                },
                domains,
            }
            console.log('res', results)
            setResultsData(results)
        }).catch(err => console.log(err));
    }, [])
    return (
        <ResultsCompositeStyled className='ResultsCompositeGraph'>
            {resultsData.domains.length > 0 && (
                <ResultsComposite results={ resultsData } />
            )}
        </ResultsCompositeStyled>
    );
}

export default ResultsCompositeGraph;

const ResultsCompositeStyled = styled.div`

`;