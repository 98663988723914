import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Accordion, Card } from "react-bootstrap";
import Questions from '../helper/Questions';
import Weaknesses from '../helper/Weaknesses'
import Confident from '../helper/Confident';
import Important from '../helper/Important';
import Domains from '../helper/Domains';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import axios from 'axios';

const CreateSurvay = () => {

    const userProfile = useSelector((state) => state.login.profile)
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn)
    const accordianIds = ['2', '3', '4', '5', '6', '7', '8']

    // Clinet Intro
    const [clientIntro, setClientIntor] = useState('')

    // Strength, Weakness, Confidnet, important  Section Details
    const [selfDeclaredStrength, setSelfDeclaredStrength] = useState([])
    const [selfDeclaredWeakness, setSelfDeclaredWeakness] = useState([])
    const [surveyUserId, setSurveyUserId] = useState([])

    // self Strength, Self Weakness Section Details
    const [surveyselfDeclaredDetails, setSurveyselfDeclaredDetails] = useState({
        'strength': [{
            'label': '',
            'id': '',
            'inputValue': ''
        }],
        'weakness': [{
            'label': '',
            'id': '',
            'inputValue': ''
        }],
        'otherQuestions': [{
            'label': '',
            'id': '',
            'inputValue': ''
        }],
    });

    // Strength, Weakness, Confidnet, important  Section Details
    const [survaySectionDetails, setSurvaySectionDetails] = useState({
        'strength': [{
            'label': '',
            'id': '',
            'selectedValue': '',
            'section': '',
            'domain': ''
        }],
        'weakness': [{
            'label': '',
            'id': '',
            'selectedValue': '',
            'section': '',
            'domain': ''
        }],
        'confident': [{
            'label': '',
            'id': '',
            'selectedValue': '',
            'section': '',
            'domain': ''
        }],
        'important': [{
            'label': '',
            'id': '',
            'selectedValue': '',
            'section': '',
            'domain': ''
        }],
        'domain': [{
            'label': '',
            'id': '',
            'selectedValue': '',
            'section': '',
            'domain': ''
        }]}
    );

     // Questions / Domains Config
     const [questionDomains, setQuestionDomains] = useState([{
        domain: '',
        question: ''
    }])

    const [domains, setDomains] = useState([])
    const [domainOptions, setdomainOptions] = useState([])
    const [otherQuestions, setOtherQuestions] = useState([])

    const { token } = useParams();

    useEffect(() => {
        if (!isLoggedIn) {
            localStorage.setItem('surveyToken', token)
            window.location.href = '/login'
        } else {
            axios.get(
                `${process.env.REACT_APP_API}/api/users/${token}/survey`,
                { withCredentials: true }
            ).then(res => { 
                const data = res.data
                setSurveyUserId(data.id)
    
                axios.get(
                    `${process.env.REACT_APP_API}/api/users/${data.userId}/domains-questions/${data.id}/survey`,
                    { withCredentials: true }
                ).then(res => { 
                    const data = res.data
                    setQuestionDomains(data)
                });
        
                axios.get(
                    `${process.env.REACT_APP_API}/api/users/${data.userId}/domains/${data.id}/survey`,
                    { withCredentials: true }
                ).then(res => { 
                    const data = res.data
                    console.log('hehe', data)
                    const clinetId = data[0].userId
                    setDomains(data)
                    setdomainOptions([...Array(data.length)].map((_,i) => ++i))
                    axios.get(
                        `${process.env.REACT_APP_API}/api/clients/${clinetId}`,
                        { withCredentials: true }
                    ).then(res => { 
                        const data = res.data.data
                        setClientIntor(data.intro ? data.intro : '')
                    }
                        );
                });
        
                axios.get(
                    `${process.env.REACT_APP_API}/api/users/${data.userId}/other-questions/${data.id}/survey`,
                    { withCredentials: true }
                ).then(res => { 
                    const data = res.data
                    setOtherQuestions(data)
                });
                
            });
    
            axios.get(
                `${process.env.REACT_APP_API}/api/users/self-declared-weakness`,
                { withCredentials: true }
            ).then(res => { 
                const data = res.data
                setSelfDeclaredWeakness(data)
            }
                );
    
          axios.get(
            `${process.env.REACT_APP_API}/api/users/self-declared-strength`,
            { withCredentials: true }
        ).then(res => { 
            const data = res.data
            setSelfDeclaredStrength(data)
            accordianIds.forEach(item => {
                document.getElementById(item).classList.add('disabled')
            })
        }
            );
        }

  }, []);

    const onGeneralSurvayDetailsInputChanges = (event, index, id) => {
        const arrayListType =  surveyselfDeclaredDetails[event.target.id]
        if (arrayListType[index]) {
            arrayListType[index] = {
                label: event.target.getAttribute('label'),
                section: event.target.id ?? undefined,
                id: parseInt(id),
                inputValue: event.target.value
            }
            setSurveyselfDeclaredDetails((prevState) => ({...prevState, arrayListType}));
        } else {
            const arrayType = surveyselfDeclaredDetails[event.target.id].push({
                label: event.target.getAttribute('label'),
                section: event.target.id ?? undefined,
                id: parseInt(id),
                inputValue: event.target.value
            })
        
            setSurveyselfDeclaredDetails((prevState) => ({...prevState, arrayType}));
        }  

        console.log('SurveyselfDeclaredDetails', surveyselfDeclaredDetails)
    }

    const onSurvayDetailsInputChanges = (event, index) => {
        const isDomainEle = event.target.id === 'domain-options';
        console.log('isDomainEle', isDomainEle)
        if (isDomainEle) {
            const newDomainOptions = domainOptions.filter(item => item !== index)
            setdomainOptions(newDomainOptions)
        }
        
        const arrayListType = survaySectionDetails[event.target.getAttribute('type')]
        if (arrayListType[index] || arrayListType.filter(item => item.id === parseInt(event.target.id)).length > 0) {
            arrayListType[index] = {
                label: event.target.getAttribute('label'),
                id: event.target.id ? parseInt(event.target.id) : undefined,
                selectedValue: event.target.value,
                section: event.target.getAttribute('type'),
                domain: event.target.getAttribute('domain')
            }
            setSurvaySectionDetails((prevState) => ({...prevState, arrayListType}));
        } else {
            const arrayType = survaySectionDetails[event.target.getAttribute('type')].push({
                label: event.target.getAttribute('label'),
                id: event.target.id ? parseInt(event.target.id) : undefined,
                selectedValue: event.target.value,
                section: event.target.getAttribute('type'),
                domain: event.target.getAttribute('domain')
            })
        
            setSurvaySectionDetails((prevState) => ({...prevState, arrayType}));
        }   

        

         console.log('yaseen', survaySectionDetails)

    }

    const validateDomainSelection = (domains) => {
        return domains.filter(item => item.selectedValue === '').length > 0 || (
            Array.from(document.querySelectorAll('[type="domain"]')).length !== domains.length
        )
    }

    const saveData = (e) => {
        e.preventDefault()
        if(validateDomainSelection(survaySectionDetails['domain']))
        {
            alert(`All input fields are requried for domain section`)
            return;
        } 
        axios.post(
            `${process.env.REACT_APP_API}/api/users/${surveyUserId}/survey`,
            { 
                createdBy: userProfile.id,
                survaySectionDetails,
                surveyselfDeclaredDetails
            }
        ).then(res => {
            console.log(1233333, res)
            window.location.href = `/survey/${res.data.id}/result`
        });
    }

    const handleSectionSubmit = (e, type) => {
        const secondType = type
        e.preventDefault()
        const intId = parseInt(e.currentTarget.id);
        let section = surveyselfDeclaredDetails[type] || survaySectionDetails[type];
        console.log('sec', type, survaySectionDetails)
        if (type === 'strengthSection') {
            type = 'strength'
            section = survaySectionDetails[type];
        } 
        if (type === 'weaknessSection') {
            type = 'weakness'
            section = survaySectionDetails[type];
        }
        console.log('123', !section, checkInputValidation(section, type, secondType))
        if ((!section || checkInputValidation(section, type, secondType)) && type !== 'intro') {
            alert(`All input fields are requried for the ${type} section`)
            return;
        }
        const newId = intId + 1
        console.log('newId', newId)
        // show completed span
        const currentAccordionItem = document.getElementById(intId.toString())
        console.log('currentAccordionItem', currentAccordionItem)
        const currentAccordionBtn = currentAccordionItem.querySelector('.accordion-done')
        if (currentAccordionBtn) currentAccordionBtn.classList.add("show");
        
        // end 
        const nextAccordionItem = document.getElementById(newId.toString())
        const nextAccordionBtn = nextAccordionItem.querySelector('.accordion-button')
        nextAccordionItem.classList.remove('disabled')
        nextAccordionBtn.click()
    }

    const checkInputValidation = (sectionInputValues, type, secondType) => {
        if (secondType === 'strengthSection' || secondType === 'weaknessSection') {
            type = secondType
        }
        switch(type) {
            case 'intro':
                return true;
            case 'strength':
                return sectionInputValues.length < 6 || sectionInputValues.filter(item => item.inputValue === '').length > 0
            case 'weakness':
                return sectionInputValues.length < 6 || sectionInputValues.filter(item => item.inputValue === '').length > 0
            case 'otherQuestions':
                return sectionInputValues.length < 6 || sectionInputValues.filter(item => item.inputValue === '').length > 0
            case 'confident':
                return sectionInputValues.filter(item => item.selectedValue === '').length > 0 || (
                    Array.from(document.querySelectorAll('[type="confident"]')).length !== sectionInputValues.length
                )
            case 'important':
                return sectionInputValues.filter(item => item.selectedValue === '').length > 0 || (
                    Array.from(document.querySelectorAll('[type="important"]')).length !== sectionInputValues.length
                )
            case 'strengthSection':
                return sectionInputValues.filter(item => item.selectedValue === '').length > 0 || (
                    Array.from(document.querySelectorAll('[type="strength"]')).length !== sectionInputValues.length
                )
            case 'weaknessSection':
                return sectionInputValues.filter(item => item.selectedValue === '').length > 0 || (
                    Array.from(document.querySelectorAll('[type="weakness"]')).length !== sectionInputValues.length
                )
        }        
    }

    return (
        <CreateSurvayStyled className='CreateSurvay inset'>
            <form onSubmit={saveData}>
                <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" id="1">
                        <Accordion.Header>Introduction</Accordion.Header>
                        <Accordion.Body>
                        <p>{clientIntro}</p>
                        <div class="text-center">
                            <button id="1" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'intro')}>Next</button>
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" id="2">
                        <Accordion.Header>Your Strengths <span className="accordion-done">Completed 1 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                        <p>Take a few moments to pause and think about the events of your day.  How did you spend your time?  Were 
                        you with others?  Were you by yourself?  How did you feel?  What have you done?  Have today’s tasks been 
                        easy?  Were there challenges?</p>
                        <p>When you think of these experiences, you will naturally think about what you did.  Let’s Also focus on 
                            how you did things, and that will leads to the important part, not what you did, but who you are.</p>
                        <p>Give yourself permission to see yourself.</p>
                        <ol>
                            { selfDeclaredStrength.map((item, i) => {
                                    if (item.isDropdown) {
                                        return (
                                            <li> 
                                                <div className="form-check">
                                                    <label>{item.question}</label>
                                                    <select class="form-select" id="strength" onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)} required>
                                                        <option selected>Select Your Answer</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li>
                                                <div className="form-check">
                                                    <label>{item.question}</label>
                                                    <textarea type="text" className="form-control" id="strength" aria-describedby="selfDeclaredStrength" placeholder="Self Declared Strength" onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)} required />
                                                </div>
                                            </li>
                                        )
                                    }
                                    
                                })}
                        </ol>
                        <div class="text-center">
                            <button id="2" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'strength')}>Next</button>
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" id="3">
                        <Accordion.Header>About Your Strength <span className="accordion-done">Completed 2 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <p>In this section, think carefully about how relevant your strength is to each of the following everyday situations.
                               Your task is to select the category from the dropdown list that best describes how relevant your strength is to that sentence. 
                            </p>

                            <p>Only a few of the sentences will genuinely fit in the absolutely 'most relevant' or 
                                'least relevant' categories. Think carefully as you choose!</p>
                            <ol>
                                { questionDomains.map((item, i) => {
                                    return (
                                        <li><Questions id={item.id} type="strength" domain={item.domain} onSurvayDetailsInputChanges={(e) => onSurvayDetailsInputChanges(e, i)} label={item.question} /></li>
                                    )
                                })}
                            </ol>
                            <div class="text-center">
                                <button id="3" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'strengthSection')}>Next</button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" id="4">
                        <Accordion.Header>Your Confidence <span className="accordion-done">Completed 3 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <p>Next, think about each of the following sentences and rate your confidence, on a 0% - 100% scale, 
                            that you could successfully do what each sentence describes.  Allow yourself to think critically about how you rate these 
                            things, for example, if the question asks your confidence to add 1 + 5, you might say 
                            100%, but if asked how confident were you that you could make a professionally decorated birthday cake, you 
                            might say 10%.<br />
                            There are no right or wrong answers. You’ll get the most out of the experience if you answer 
                            honestly and carefully. Let’s go! </p>
                            <ol>
                            { questionDomains.map((item, i) => {
                                    return (
                                        <li><Confident id={item.id} type="confident" domain={item.domain} onSurvayDetailsInputChanges={(e) => onSurvayDetailsInputChanges(e, i)} label={item.question} /></li>
                                    )
                                })}
                            </ol>   
                            <div class="text-center">
                                <button id="4" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'confident')}>Next</button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" id="5">
                        <Accordion.Header>What's Important to You <span className="accordion-done">Completed 4 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <p>Now think about the sentences and rate how important they are to you in your life, on a 0 - 100 scale.   If 
                            something completely irrelevant, select 'not part of my life'.</p>
                            <ol>
                            { questionDomains.map((item, i) => {
                                    return (
                                        <li><Important id={item.id} type="important" domain={item.domain} onSurvayDetailsInputChanges={(e) => onSurvayDetailsInputChanges(e, i)} label={item.question} /></li>
                                    )
                                })}
                            </ol>  
                            <div class="text-center">
                                <button id="5" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'important')}>Next</button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" id="6">
                        <Accordion.Header>Your Weaknesses <span className="accordion-done">Completed 5 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <ol>
                                { selfDeclaredWeakness.map((item, i) => {
                                    if (item.isDropdown) {
                                        return (
                                            <li> 
                                                <div className="form-check">
                                                    <label>{item.question}</label>
                                                    <select class="form-select" id="weakness" onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)}>
                                                        <option selected>Select Your Answer</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li>
                                                <div className="form-check">
                                                    <label>{item.question}</label>
                                                    <textarea type="text" className="form-control" id="weakness" aria-describedby="selfDeclaredStrength" placeholder="Self Declared Strength" onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)} required/>
                                                </div>
                                            </li>
                                        )
                                    }
                                    })}
                            </ol>
                            <div class="text-center">
                                <button id="6" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'weakness')}>Next</button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" id="7">
                        <Accordion.Header>About Your Weakness <span className="accordion-done">Completed 6 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <p>In the next section, think carefully about how relevant your weakness is to each of the following sentences. 
                            Your task is to indicate the relation of your weakness to each of them. Remember, only a few of the sentences 
                            will genuinely fit in the absolutely 'most relevant' or 'least relevant' categories. 
                            Think carefully as you choose!</p>
                            <ol>
                            { questionDomains.map((item, i) => {
                                    return (
                                        <li><Weaknesses id={item.id} type="weakness" domain={item.domain} onSurvayDetailsInputChanges={(e) => onSurvayDetailsInputChanges(e, i)} label={item.question} /></li>
                                    )
                                })}
                            </ol>
                            <div class="text-center">
                                <button id="7" class="btn btn-primary" type="submit" onClick={(e) => handleSectionSubmit(e, 'weaknessSection')}>Next</button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8" id="8">
                        <Accordion.Header>Final thoughts <span className="accordion-done">Completed 7 Of 7</span></Accordion.Header>
                        <Accordion.Body>
                            <p>Please rank these in order of importance to you, where 1 is most important. </p>
                            
                            { domains.map((item, i) => {
                                return (
                                    <Domains type="domain" domain={item.domain} onSurvayDetailsInputChanges={(e) => onSurvayDetailsInputChanges(e, i)} label={item.domain} domainOptions={ domainOptions } />
                                )
                            })}
                            { otherQuestions .length ? (
                                <>
                                    { otherQuestions.map((item, i) => {
                                        return (
                                                <div className="form-group other-questions">
                                                    <label>{item.question}</label>
                                                    <textarea type="text" className="form-control" id="otherQuestions" placeholder={item.question} onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)} required />
                                                </div>
                                        )
                                    })}
                                </>
                            ): ''}
                            
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="8" id="8">
                        <Accordion.Header>Other Questions Section <span className="accordion-done">Completed 8 Of 7</span></Accordion.Header>
                        <Accordion.Body>

                        <ol>
                        { otherQuestions.map((item, i) => {
                            return (
                                <li>
                                    <div className="form-group other-questions">
                                        <label>{item.question}</label>
                                        <textarea type="text" className="form-control" id="otherQuestions" placeholder={item.question} onChange={(e) => onGeneralSurvayDetailsInputChanges(e, i, item.id)} required />
                                    </div>
                                </li>
                            )
                        })}
                        </ol>
                        </Accordion.Body>
                    </Accordion.Item> */}
                </Accordion>
                <button className="btn btn-primary w-100 create" type="submit">Submit</button>
            </form>
        </CreateSurvayStyled>
    );
}

export default CreateSurvay;

const CreateSurvayStyled = styled.div`
.other-questions {
    margin-bottom: 10px;
}
label {
    margin-bottom: 5px;
    font-size: 14px;
}
.form-check {
    margin-bottom: 10px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}
textarea {
    font-size: 14px;
}

textarea::placeholder, input::placeholder {
    font-size: 10px
}

button {
    &.create {
        margin-top: 10px;
    }
    &.accordion-button {
        position: relative
    }
    .accordion-done {
        visibility: hidden;
        position: absolute;
        right: 50px
    }
    .accordion-done.show {
        visibility: visible;
    }
}

.accordion-item.disabled { 
    pointer-events: none;
}

h4.margin {
    margin-top: 20px;
}

`;