import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const userProfile = useSelector((state) => state.login.profile);
    const logout = () => {
        location.href = `${process.env.REACT_APP_API}/logout`;
    };

    return (
        <HeaderStyled className="Header">
            <Navbar collapseOnSelect expand="lg" bg="green" variant="green">
                <Container>
                    <Navbar.Brand href="/"><img src="/assets/icons/SeeDegree-White.svg" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className="justify-content-end"
                    >
                        <Nav>
                            <Nav.Link href="/test">Test</Nav.Link>
                            <Nav.Link href="/results-example">Results</Nav.Link>
                            {isLoggedIn && userProfile.roleId === 1 ? (
                                <>
                                    <Nav.Link href="/add-new-client">
                                        Add User
                                    </Nav.Link>
                                    <Nav.Link href="/admin-list">
                                        Admin List
                                    </Nav.Link>
                                    <Nav.Link href="/client-list">
                                        Client List
                                    </Nav.Link>
                                    <Nav.Link onClick={logout}>Logout</Nav.Link>
                                </>
                            ) : isLoggedIn && userProfile.roleId === 3 ? (
                                <>
                                    <Nav.Link href="/survey-list">
                                        My Survey List
                                    </Nav.Link>
                                    <Nav.Link onClick={logout}>Logout</Nav.Link>
                                </>
                            ) : isLoggedIn && userProfile.roleId === 2 ? (
                                <>
                                    <Nav.Link href="/survey-list">
                                        My Survey Results
                                    </Nav.Link>
                                    <Nav.Link
                                        href={`/client/${userProfile.clientId}`}
                                    >
                                        My Account Details
                                    </Nav.Link>
                                    <Nav.Link onClick={logout}>Logout</Nav.Link>
                                </>
                            ) : (
                                ''
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </HeaderStyled>
    );
};

export default Header;

const HeaderStyled = styled.div`
    background-color: #42bdef;
    text-align: center;
    padding: 20px 0px;
    margin: 0px 0px 50px;
    color: white;

    &.Header {
        img {
            height: 40px;
        }
    }

    .container {
        .navbar-brand,
        .nav-link {
            color: white;
        }
    }
`;
