import React from 'react';
import styled from 'styled-components';

const Weaknesses = ({label, type, domain, id, onSurvayDetailsInputChanges}) => {

    return (
        <WeaknessesStyled>
            <label htmlFor="0">{label}</label>
            <select className="form-control" id={id} domain={domain} type={type} label={label} onChange={onSurvayDetailsInputChanges}>
                <option value="">Select a value</option> 
                <option value="Most relevant to my weakness. Because of my personal 
                    strength it is most likely that I would do this.">Most relevant to my weakness. Because of my personal 
                    strength it is most likely that I would do this.</option>
                <option value="Relevant to my weakness. Because of my 
                    personal strength it is somewhat likely that I would do 
                    this.">Relevant to my weakness. Because of my 
                    personal strength it is somewhat likely that I would do 
                    this.
                </option>
                <option value="Irrelevant. This is not relevant to my personal ">Irrelevant. This is not relevant to my personal 
                strength.
                </option>
                <option value="Negatively relevant to my weakness.
                    Because of my personal strength, it is somewhat 
                    unlikely that I would do this.">Negatively relevant to my weakness.
                    Because of my personal strength, it is somewhat 
                    unlikely that I would do this.
                </option>
                <option value="Most negatively relevant to my weakness 
                    of my personal strength, it is most unlikely that I would 
                    do this.">Most negatively relevant to my weakness 
                    of my personal strength, it is most unlikely that I would 
                    do this.
                </option>
            </select>
        </WeaknessesStyled>
    );
}

export default Weaknesses;

const WeaknessesStyled = styled.div`
    margin-bottom: 10px;
    label {
        margin-bottom: 3px;
        font-size: 18px;
    }
    select {
        font-size: 14px;
    }

`;