import React from 'react';
import styled from 'styled-components';

/* Components ---------------------------*/
import ResultsComposite from '../../../Results//ResultsComposite/ResultsComposite.jsx';

/* Scripts ---------------------------*/
import { resultsData } from './resultsCompositeExampleDataEight.js';
// import { resultsData } from './resultsCompositeExampleData.js';

const ResultsCompositeExample = () => {
    return (
        <ResultsCompositeExampleStyled className='ResultsCompositeExample'>
            <ResultsComposite results={ resultsData } />
        </ResultsCompositeExampleStyled>
    );
}

export default ResultsCompositeExample;

const ResultsCompositeExampleStyled = styled.div`

`;