import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

/* Scripts ---------------------------*/
import { getCurrentPercOption } from '../../../../Common/utils.js';

/* Components ---------------------------*/
import ConfidenceCircle from '../../../../Common/Circles/ConfidenceCircle.jsx';
import ImportanceCircle from '../../../../Common/Circles/ImportanceCircle.jsx';
import Icon from './Icon.jsx';
import Title from './Title.jsx';
import Percentages from './Percentages.jsx';
import PopUp from './PopUp/PopUp.jsx';
import Nav from './Nav/Nav.jsx';

const Domain = ({ domainResult }) => {

    const { id, title, icon, percents, popUp, positiveOrNegativeConnection } = domainResult;

    const noRelation = positiveOrNegativeConnection === 'noRelation'

    const currPercOption = getCurrentPercOption(percents);
    const showPercentsAndRings = currPercOption ? currPercOption.confidence + currPercOption.importance > 0 : false;

    const hasPopUp = !!popUp.messages.length;

    const theClassName = classNames({
        Domain: true,
        hasPopUp: hasPopUp,
    });

    return (
        <DomainStyled className={theClassName}>
            <ConfidenceCircle percentage={100} color={'#fff'} />
            {showPercentsAndRings && (
                <>
                    <ConfidenceCircle percentage={currPercOption.confidence} />
                </>
            )}
            <Icon icon={icon} />
            {showPercentsAndRings && (
                <ImportanceCircle percentage={currPercOption.importance} />
            )}
            <Title
                title={title}
                domainResult={domainResult}
                hasPopUp={hasPopUp}
                bgColor={currPercOption ? currPercOption.titleBgColor : '#fff'}
                noRelation={ noRelation }
            />
            {showPercentsAndRings && <Percentages percents={currPercOption} />}
            {popUp.show && <PopUp id={id} popUp={popUp} />}
            <Nav id={id} percents={percents} />
        </DomainStyled>
    );
};

export default Domain;

const DomainStyled = styled.div`

    .ConfidenceCircle {
        /* Anchor to center */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        /* Responsive Scaling */
        width: 24vw;
    }
    .ImportanceCircle {
        /* Anchor to center */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        /* Responsive Scaling */
        width: 14vw;
    }

    &.hasPopUp {
        .Title {
            &:hover {
                cursor: pointer;
                .InformationIcon {
                    .information-icon-circle {
                        fill: #edc55c;
                    }
                }
            }
        }
    }
`;
